import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

@Component({
  selector: 'information-security.component',
  templateUrl: './information-security.component.html',
  styleUrls: ['./information-security.component.css']
})



export class InformationSecurityComponent {
  username: string = '';
  password: string = '';
  isecurityForm: FormGroup;
  errorMessage: any;
  unAuthorizedUser: boolean = false;
  validateAdmin: boolean = false;
  companywebsite: boolean = false;
 // softwarenameandedition: boolean = false;
  mySwitch: boolean = false;
  dtransmission_dropdownList: any[] = [];
  sintegration_dropdownList: any[] = [];
  saccess_dropdownList: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  requireDescribePT: boolean = false;
  requireDescribeSI: boolean = false;
  requireDescribeCE: boolean = false;  
  requireOtherSoftware: boolean = false;  
  //softwareName: any[] = [];
  //softwareEdition: any[] = [];
  //softwareNameList :  any[] = [];
  auth: any;
  //showEdition : boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.isecurityForm = this.formBuilder.group({
      susage: ["", Validators.required],
      smaintenance: ["", Validators.required],
      dtransmission: ["", Validators.required],
      sintegration: ["", Validators.required],
      shosting: ["", Validators.required],
      sauthentication: ["", Validators.required],
      saccess: ["", Validators.required],
     // sname: [""],
     // sedition: [""],
    //  sreview: ["", Validators.required],
      cwebsite: [null],
      describePT: [null],
      describeSI: [null],
      describeCE: [null],
      othersoftware: [null]
      
    }); 
  }


  ngOnInit() {

    this.dtransmission_dropdownList = [
      { item_id: "1", item_text: 'Vendor will use INDG Workstations, Corp Network, and/or remote logins' },
      { item_id: "2", item_text: 'Vendor will use its own laptops, PC/mobile devices, data centers' },
      { item_id: "3", item_text: 'Vendor will use its cloud solution or a third partys’ cloud to provide services to INDG' },
      { item_id: "4", item_text: 'Physical (hard copies) or Electronic media (hard drives, back up tapes)' },
      { item_id: "5", item_text: 'Email delivery (e.g. INDG to non-INDG email address)' },
      { item_id: "6", item_text: 'Other (describe)' },
      { item_id: "7", item_text: 'Not Applicable (e.g. Product purchase only, hardware or installation only, subscriptions' },
    ];

    this.sintegration_dropdownList = [
      { item_id: "1", item_text: 'Internet portal / Website provided by vendor' },
      { item_id: "2", item_text: 'Mobile Application provided by vendor' },
      { item_id: "3", item_text: 'Commercial software for installation within INDG IT Environments' },
      { item_id: "4", item_text: 'Hardware for installation within INDG IT Environments' },
      { item_id: "5", item_text: 'API Integration' },
      { item_id: "6", item_text: 'FIX / Live Connection' },
      { item_id: "7", item_text: 'FTP/SFTP Transmission' },
      { item_id: "8", item_text: 'VPN or other dedicated lines' },
      { item_id: "9", item_text: 'Not Applicable (e.g. there no integrations or applications that will be used)' },
      { item_id: "10", item_text: 'Other (describe)' },
    ];

    this.saccess_dropdownList = [
      { item_id: "1", item_text: 'INDG PC log-in or Domain access (CORP or other INDG domain account)' },
      { item_id: "2", item_text: 'Limited access to particular systems (Name systems)' },
      { item_id: "3", item_text: 'Unlimited access to particular systems (Name systems)' },
      { item_id: "4", item_text: 'Remote Access for end user (e.g. VPN)' },
      { item_id: "5", item_text: 'Not applicable - (e.g. vendor will not access INDG systems)' },
    ];

    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true,
    };

    this.getDropdownValues(); 
    this.companywebsite = false;
 // this.softwarenameandedition = false;
    let susage = localStorage.getItem("susage")
    let smaintenance = localStorage.getItem("smaintenance")
    let saccess = localStorage.getItem("saccess")
    let saccessValue = localStorage.getItem("saccessValue")
    let shosting = localStorage.getItem("shosting")
    let sauthentication = localStorage.getItem("sauthentication")
    let dtransmission = localStorage.getItem("dtransmission")
    let sintegration = localStorage.getItem("sintegration")
    let dtransmissionValue = localStorage.getItem("dtransmissionValue")
    let sintegrationValue = localStorage.getItem("sintegrationValue")
 //   let sreview = localStorage.getItem("sreview");
 //   let sedition = localStorage.getItem("softwareedition");   
 //   let sname = localStorage.getItem("sname");
    let cwebsite = localStorage.getItem("cwebsite");
    let describePT = localStorage.getItem("describePT");
    let describeSI = localStorage.getItem("describeSI");
    let describeCE = localStorage.getItem("describeCE");
    let othersoftware = localStorage.getItem("othersoftware");

    setTimeout(() => {
    //this.getSoftwareEdition(sname);
    }, 3000);
    if (susage !== null && smaintenance !== null && saccess !== null && shosting !== null && dtransmission !== null && sintegration !== null
      && saccessValue !== null && dtransmissionValue !== null && sintegrationValue !== null && sauthentication !== null) {
      let dtransmissionFinalArray: any[] = [];
      let sintegrationFinalArray: any[] = [];
      let saccessFinalArray: any[] = [];

      let dtransmissionArray: any[] = [];
      let sintegrationArray: any[] = [];
      let saccessArray: any[] = [];

      let dtransmissionValueArray: any[] = [];
      let sintegrationValueArray: any[] = [];
      let saccessValueArray: any[] = [];

      dtransmissionArray = dtransmission.split(',')
      sintegrationArray = sintegration.split(',')
      saccessArray = saccess.split(',')

      dtransmissionValueArray = dtransmissionValue.split(':')
      sintegrationValueArray = sintegrationValue.split(':')
      saccessValueArray = saccessValue.split(':')

      for (let i = 0; i < dtransmissionArray.length; i++) {
        dtransmissionFinalArray.push({ item_id: dtransmissionArray[i], item_text: dtransmissionValueArray[i] });
      }

      for (let i = 0; i < sintegrationArray.length; i++) {
        sintegrationFinalArray.push({ item_id: sintegrationArray[i], item_text: sintegrationValueArray[i] });
      }

      for (let i = 0; i < saccessArray.length; i++) {
        saccessFinalArray.push({ item_id: saccessArray[i], item_text: saccessValueArray[i] });
      }

      if (describeSI !== null && describeSI !== 'null' && describeSI !== undefined && describeSI !== '' &&
        describePT !== null && describePT !== 'null' && describePT !== undefined && describePT !== '' ) {
          this.requireDescribePT = true;
          this.requireDescribeSI = true;       
          this.isecurityForm = this.formBuilder.group({
            susage: [susage, Validators.required],
            smaintenance: [smaintenance, Validators.required],
            saccess: [saccessFinalArray, Validators.required],
            shosting: [shosting, Validators.required],
            sauthentication: [sauthentication, Validators.required],
            dtransmission: [dtransmissionFinalArray, Validators.required],
            sintegration: [sintegrationFinalArray, Validators.required],
          //   sreview: [sreview, Validators.required],          
          //  sname: [sname],
          //  sedition: [sedition],
            cwebsite: [cwebsite],
            describePT: [describePT, Validators.required],
            describeSI: [describeSI, Validators.required],
            describeCE: [describeCE],
            othersoftware: [othersoftware]
        });
      } else if (describeSI !== null && describeSI !== 'null' && describeSI !== undefined && describeSI !== '' &&
        (describePT === null || describePT === 'null' || describePT === undefined || describePT === '')) {
          this.requireDescribeSI = true;
          this.requireDescribePT = false;
          this.isecurityForm = this.formBuilder.group({
            susage: [susage, Validators.required],
            smaintenance: [smaintenance, Validators.required],
            saccess: [saccessFinalArray, Validators.required],
            shosting: [shosting, Validators.required],
            sauthentication: [sauthentication, Validators.required],
            dtransmission: [dtransmissionFinalArray, Validators.required],
            sintegration: [sintegrationFinalArray, Validators.required],
       //     sreview: [sreview, Validators.required],
       //     sname: [sname],
       //     sedition: [sedition],
            cwebsite: [cwebsite],
            describePT: [null],
            describeSI: [describeSI, Validators.required],
            describeCE: [describeCE],
            othersoftware: [othersoftware]
          });
      } else if ((describeSI === null || describeSI === 'null' || describeSI === undefined || describeSI !== '') &&
        (describePT !== null && describePT !== 'null' && describePT !== undefined && describePT !== '')) {
          this.requireDescribeSI = false;
          this.requireDescribePT = true;        
          this.isecurityForm = this.formBuilder.group({
            susage: [susage, Validators.required],
            smaintenance: [smaintenance, Validators.required],
            saccess: [saccessFinalArray, Validators.required],
            shosting: [shosting, Validators.required],
            sauthentication: [sauthentication, Validators.required],
            dtransmission: [dtransmissionFinalArray, Validators.required],
            sintegration: [sintegrationFinalArray, Validators.required],
      //      sreview: [sreview, Validators.required],
      //      sname: [sname],
      //      sedition: [sedition],
            cwebsite: [cwebsite],
            describePT: [describePT, Validators.required],
            describeSI: [null],
            describeCE: [describeCE],
            othersoftware: [othersoftware]
          });
      } else {
        this.requireDescribeSI = false;
        this.requireDescribePT = false;    
        this.isecurityForm = this.formBuilder.group({
          susage: [susage, Validators.required],
          smaintenance: [smaintenance, Validators.required],
          saccess: [saccessFinalArray, Validators.required],
          shosting: [shosting, Validators.required],
          sauthentication: [sauthentication, Validators.required],
          dtransmission: [dtransmissionFinalArray, Validators.required],
          sintegration: [sintegrationFinalArray, Validators.required],
      //    sreview: [sreview, Validators.required],
      //    sname: [sname],
      //    sedition: [sedition],
          cwebsite: [cwebsite],
          describePT: [null],
          describeSI: [null],
          describeCE: [describeCE],
          othersoftware: [othersoftware]
      });
    }
   }
  

    if (susage === "1") {
      this.companywebsite = true;
    // this.softwarenameandedition = true;
    //  this.showEdition = true;
    //  const reg = '/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/'
      const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'; 
      this.isecurityForm.controls['cwebsite'].setValidators([Validators.required, Validators.pattern(reg)]); 
    //  this.isecurityForm.controls['sname'].setValidators(Validators.required); 
    //  this.isecurityForm.controls['sedition'].setValidators(Validators.required);
    } else {
      this.companywebsite = false;
     // this.softwarenameandedition = false;
     // this.showEdition = false;
      this.isecurityForm.controls['cwebsite'].clearValidators();
      this.isecurityForm.controls['cwebsite'].updateValueAndValidity();
      this.isecurityForm.controls['cwebsite'].reset();
      localStorage.setItem("cwebsite", ''); 
    //  this.isecurityForm.controls['sname'].clearValidators();
    //  this.isecurityForm.controls['sname'].updateValueAndValidity();
    //  this.isecurityForm.controls['sname'].reset();
    //   localStorage.setItem("sname", '');
    //  this.isecurityForm.controls['sedition'].clearValidators();
    //   this.isecurityForm.controls['sedition'].updateValueAndValidity();
    //   this.isecurityForm.controls['sedition'].reset();
    //  localStorage.setItem("softwareedition", '');
    }

    
    if (shosting === "3") {
      this.requireDescribeCE = true;
      this.isecurityForm.controls['describeCE'].setValidators(Validators.required);
    } else {
      this.requireDescribeCE = false;
      this.isecurityForm.controls['describeCE'].clearValidators();
      this.isecurityForm.controls['describeCE'].updateValueAndValidity();
      this.isecurityForm.controls['describeCE'].reset();
      localStorage.setItem("describeCE", '');
    }

  /*  if (sname === "Other") {
      this.requireOtherSoftware = true;
      this.isecurityForm.controls['othersoftware'].setValidators(Validators.required);
    } else {
      this.requireOtherSoftware = false;
      this.isecurityForm.controls['othersoftware'].clearValidators();
      this.isecurityForm.controls['othersoftware'].updateValueAndValidity();
      this.isecurityForm.controls['othersoftware'].reset();
      localStorage.setItem("othersoftware", '');
    }
  */   
  }

  getSoftwareUsage(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("susage", event.target.value)
      if (event.target.value == "1") {
        this.companywebsite = true;
     //    this.softwarenameandedition = true;
     //   const reg = '/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/'
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';    
        this.isecurityForm.controls['cwebsite'].setValidators([Validators.required, Validators.pattern(reg)]);   
    //    this.isecurityForm.controls['sname'].setValidators(Validators.required);  
    //    this.isecurityForm.controls['sedition'].setValidators(Validators.required);  
          this.isecurityForm.controls['othersoftware'].setValidators(Validators.required); 
        
      } else {
        this.companywebsite = false;
      //  this.softwarenameandedition = false;
      //  this.showEdition = false;
        this.isecurityForm.controls['cwebsite'].clearValidators();
        this.isecurityForm.controls['cwebsite'].updateValueAndValidity();
        this.isecurityForm.controls['cwebsite'].reset();
        localStorage.setItem("cwebsite", '');
      //  this.isecurityForm.controls['sname'].clearValidators();
      //  this.isecurityForm.controls['sname'].updateValueAndValidity();
      //  this.isecurityForm.controls['sname'].reset();
      //  localStorage.setItem("sname", '');
      //  this.isecurityForm.controls['sedition'].clearValidators();
      //  this.isecurityForm.controls['sedition'].updateValueAndValidity();
      //  this.isecurityForm.controls['sedition'].reset();
      //  localStorage.setItem("softwareedition", '');
      }
    }

  }


  getSoftwareHosting(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("shosting", event.target.value)
      if (event.target.value == "3") {
        this.requireDescribeCE = true;
        this.isecurityForm.controls['describeCE'].setValidators(Validators.required);
      } else {
        this.requireDescribeCE = false;
        this.isecurityForm.controls['describeCE'].clearValidators();
        this.isecurityForm.controls['describeCE'].updateValueAndValidity();
        this.isecurityForm.controls['describeCE'].reset();
        localStorage.setItem("describeCE", '');
      }
    }

  }

  resetForm() {
    if (this.mySwitch === true) {
      this.isecurityForm.reset();
      localStorage.setItem("susage", "");
      localStorage.setItem("smaintenance", "");
      localStorage.setItem("saccess", "");
      localStorage.setItem("saccessValue", "");
      localStorage.setItem("shosting", "");
      localStorage.setItem("sauthentication", "");
      localStorage.setItem("dtransmission", "");
      localStorage.setItem("sintegration", "");
      localStorage.setItem("dtransmissionValue", "");
      localStorage.setItem("sintegrationValue", "");
    //  localStorage.setItem("sreview", "");
      localStorage.setItem("cwebsite", "");
    //  localStorage.setItem("sname", "");
    //  localStorage.setItem("softwareedition", "");
      localStorage.setItem("describePT", "");
      localStorage.setItem("describeSI", "");
      localStorage.setItem("describeCE", "");
      this.companywebsite = false;
    ////  this.softwarenameandedition = false;
    }
  }

  // System Acccess
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  // Data Transmission
  onItemSelectDataTransmission(item: any) {
    console.log('onItemSelect', item);
    if (item.item_id === '6') {
      this.requireDescribePT = true;
      this.isecurityForm.controls['describePT'].setValidators(Validators.required);
    }
  }
  onItemDeSelectDataTransmission(item: any) {
    console.log('onItemDeSelect', item);
    if (item.item_id === '6') {
      this.requireDescribePT = false;
      this.isecurityForm.controls['describePT'].clearValidators();
      this.isecurityForm.controls['describePT'].updateValueAndValidity();
      this.isecurityForm.controls['describePT'].reset();
      localStorage.setItem("describePT", '');
    }
  }
  onSelectAllDataTransmission(items: any) {
    console.log('onSelectAll', items);
    this.requireDescribePT = true;
    this.isecurityForm.controls['describePT'].setValidators(Validators.required);
  }
  onUnSelectAllDataTransmission() {
    console.log('onUnSelectAll fires');
    this.requireDescribePT = false;
    this.isecurityForm.controls['describePT'].clearValidators();
    this.isecurityForm.controls['describePT'].updateValueAndValidity();
    this.isecurityForm.controls['describePT'].reset();
    localStorage.setItem("describePT", '');
  }

  // Service Integration
  onItemSelectServiceIntegration(item: any) {
    console.log('onItemSelect', item);
    if (item.item_id === '10') {
      this.requireDescribeSI = true;
      this.isecurityForm.controls['describeSI'].setValidators(Validators.required);
    }
  }
  onItemDeSelectServiceIntegration(item: any) {
    console.log('onItemDeSelect', item);
    if (item.item_id === '10') {
      this.requireDescribeSI = false;
      this.isecurityForm.controls['describeSI'].clearValidators();
      this.isecurityForm.controls['describeSI'].updateValueAndValidity();
      this.isecurityForm.controls['describeSI'].reset();
      localStorage.setItem("describeSI", '');
    }
  }
  onSelectAllServiceIntegration(items: any) {
    console.log('onSelectAll', items);
    this.requireDescribeSI = true;
    this.isecurityForm.controls['describeSI'].setValidators(Validators.required);
  }
  onUnSelectAllServiceIntegration() {
    console.log('onUnSelectAll fires');
    this.requireDescribeSI = false;
    this.isecurityForm.controls['describeSI'].clearValidators();
    this.isecurityForm.controls['describeSI'].updateValueAndValidity();
    this.isecurityForm.controls['describeSI'].reset();
    localStorage.setItem("describeSI", '');
  }

  onSubmit(): void {
    console.warn('Form Values', this.isecurityForm.value);
    console.log(this.isecurityForm.valid);
    if (this.isecurityForm.valid) {

      let systemAccessID = '';
      let systemAccessValue = '';
      const sAccessArray: any[] = this.isecurityForm.value.saccess;
      sAccessArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (systemAccessID !== '') {
            systemAccessID = systemAccessID + ',' + item.item_id;
          } else {
            systemAccessID = item.item_id;
          }
          if (systemAccessValue !== '') {
            systemAccessValue = systemAccessValue + ':' + item.item_text;
          } else {
            systemAccessValue = item.item_text;
          }
        }
      })

      let dataTrasmissionID = '';
      let dataTrasmissionValue = '';
      const dtransmissionArray: any[] = this.isecurityForm.value.dtransmission;
      dtransmissionArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (dataTrasmissionID !== '') {
            dataTrasmissionID = dataTrasmissionID + ',' + item.item_id;
          } else {
            dataTrasmissionID = item.item_id;
          }
          if (dataTrasmissionValue !== '') {
            dataTrasmissionValue = dataTrasmissionValue + ':' + item.item_text;
          } else {
            dataTrasmissionValue = item.item_text;
          }
        }
      })

      let systemIntegrationID = '';
      let systemIntegrationValue = '';
      const sintegrationArray: any[] = this.isecurityForm.value.sintegration;
      sintegrationArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (systemIntegrationID !== '') {
            systemIntegrationID = systemIntegrationID + ',' + item.item_id;
          } else {
            systemIntegrationID = item.item_id;
          }
          if (systemIntegrationValue !== '') {
            systemIntegrationValue = systemIntegrationValue + ':' + item.item_text;
          } else {
            systemIntegrationValue = item.item_text;
          }
        }
      })


      localStorage.setItem("susage", this.isecurityForm.value.susage);
      localStorage.setItem("smaintenance", this.isecurityForm.value.smaintenance);
      localStorage.setItem("saccess", systemAccessID);
      localStorage.setItem("saccessValue", systemAccessValue);
   //   localStorage.setItem("shosting", this.isecurityForm.value.shosting);
      localStorage.setItem("sauthentication", this.isecurityForm.value.sauthentication);

      localStorage.setItem("dtransmission", dataTrasmissionID);
      localStorage.setItem("dtransmissionValue", dataTrasmissionValue);
      localStorage.setItem("sintegration", systemIntegrationID);
      localStorage.setItem("sintegrationValue", systemIntegrationValue);
      localStorage.setItem("cwebsite", this.isecurityForm.value.cwebsite);
      localStorage.setItem("othersoftware", this.isecurityForm.value.othersoftware);
    //  localStorage.setItem("sname", this.isecurityForm.value.sname);
    //  localStorage.setItem("softwareedition", this.isecurityForm.value.sedition);

      
      if (this.requireDescribeCE) {
        localStorage.setItem("describeCE", this.isecurityForm.value.describeCE);
      }

      if (this.requireDescribePT) {
        localStorage.setItem("describePT", this.isecurityForm.value.describePT);
      }

      if (this.requireDescribeSI) {
        localStorage.setItem("describeSI", this.isecurityForm.value.describeSI);
      }

      this.router.navigate(['../vendor-questionnaire-reputational'], { relativeTo: this.route });

    }
    /*  if (this.securityvendorForm.valid) {
        this.pushDetailsToJIRA(this.securityvendorForm.value);
      } */
  }

  getDropdownValues() {
    this.auth = localStorage.getItem("Auth");
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'issue/createmeta/ISSD/issuetypes/10203?maxResults=200'; 
    this.http.get<any>(environment.jiraAPI +  params, { headers })
       .subscribe({
        next: data => {


    /*      const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // software Name        
                    result.forEach(element => {          
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_68200" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const softwareNameList: any[] = element.allowedValues;             
                softwareNameList.forEach(item => {
                  this.softwareName.push(item.value);
                })
              }
            });

            console.log('Software Name ', this.softwareName);    

        } */

        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
        
      })
  } 


 /* getDropdownValues() {
    const params = new HttpParams()
      .set('username', 'sb118362')
      .set('password', 'Godplease#1290');
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };

    this.http.get<any>(environment.api + "dropdown", { params, headers })
      .subscribe({
        next: data => {


 const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // software Name        
                    result.forEach(element => {          
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_68200" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const softwareNameList: any[] = element.allowedValues;             
                softwareNameList.forEach(item => {
                  this.softwareName.push(item.value);
                })
              }
            });

            console.log('Software Name ', this.softwareName);    

        }

        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  } */

 /* getSoftwareEdition(value: any) {
    this.isecurityForm.controls['sedition'].clearValidators();
    this.isecurityForm.controls['sedition'].updateValueAndValidity();
    this.isecurityForm.controls['sedition'].reset();
 //   localStorage.setItem("softwareedition", '');
  if (value === 'Other') {
      this.requireOtherSoftware = true; 
      this.isecurityForm.controls['othersoftware'].setValidators(Validators.required);
      localStorage.setItem("softwareedition", '');
  } else {
      this.requireOtherSoftware = false;
      this.isecurityForm.controls['othersoftware'].clearValidators();
      this.isecurityForm.controls['othersoftware'].updateValueAndValidity();
      this.isecurityForm.controls['othersoftware'].reset();
      localStorage.setItem("othersoftware", '');  
  }

    this.showEdition = false;
    this.softwareEdition = [];
    let sname = value;
    if (sname !== null && sname !== undefined && sname !== "") {
       this.softwareNameList.forEach(element => {
        if (element !== null && element !== undefined && element !== "" && element.value !== null && element.value !== undefined && element.value !== "" && element.disabled === false
          && element.children !== null && element.children !== undefined && element.children !== ""
          && element.children.length > 0 && element.value === sname) {     
        this.showEdition = true;
        this.isecurityForm.controls['sedition'].setValidators(Validators.required);
        let childValues: any[] =element.children;
        childValues.forEach(item => {
          if (item.disabled === false) {
            this.softwareEdition.push(item.value);
          }
        });
      }

    });
  }
    console.log('Software Edition ', this.softwareEdition);
    console.log( 'Edition ', localStorage.getItem("softwareedition"));
    this.isecurityForm.patchValue({
      sedition: localStorage.getItem("softwareedition")
  });
  */


}
