import { AuthConfig, OAuthService, JwksValidationHandler } from "angular-oauth2-oidc";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';




@Injectable()

export class OktaService {
    constructor(private oauthService: OAuthService, private router: Router, private route: ActivatedRoute) {
        this.router.events.forEach((event) => {       
            if(event instanceof NavigationStart) {
                //event.navigationTrigger === 'popstate' || event.navigationTrigger === 'imperative'
              if (event.url == '/login' && event.navigationTrigger === 'imperative') {   
                this.router.navigate(['../vendor-details'], { relativeTo: this.route });
              }
            }
          });
       
    }
    public okta: AuthConfig = {
        issuer: environment.issuer,   
        oidc: true,
        redirectUri: window.location.origin + '/login',
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientId,
        scope: 'openid profile email address phone offline_access',
        showDebugInformation: true      
    };
    public configure() {
        const oauthService = this.oauthService;
        const router = this.router;
        oauthService.configure(this.okta);
        oauthService.tokenValidationHandler = new JwksValidationHandler();
        oauthService.showDebugInformation = true;
        oauthService.loadDiscoveryDocument().then((doc) => {  
            console.log('Discovery ', doc);        
            oauthService.tryLogin({
                onTokenReceived: (context : any) => {
                    console.log('Token', context);         
                    router.navigateByUrl(context.state);
                },
                onLoginError: (context) => {
                    console.log('error', context);
                 //   alert("Currently, you don't have access to Security Vendor Chiclet in Okta, please submit a IT Service Desk Ticket");
                }
            });
        });
    }

    public getClaims() {      
        return this.oauthService.getIdentityClaims();
    }





}
