import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'vendor-questionnaire.component',
  templateUrl: './vendor-questionnaire.component.html',
  styleUrls: ['./vendor-questionnaire.component.css']
})
export class VendorQuestionnaireComponent implements OnInit {
  dropdownList: any[] = [];
  slocation_dropdownList: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  securityvendorQuestionnaireForm: FormGroup;
  finalTickets: any[] = [];
  ticketResult: any[] = [];
  ticketCount: number = 0;
  errorMessage: any;
  ticket: string = "";
  p: Number = 1;
  count: Number = 10;
  checkStatus = "Open";
  isOpen = true;
  user: any;
  password: any;
  adminView: any;
  dueTickets: string = "";
  auth: any;
  requireDescribe: boolean = false;

  constructor(private http: HttpClient, private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    this.securityvendorQuestionnaireForm = this.formBuilder.group({
      vlocation: ["", Validators.required],
      ulocation: ["", Validators.required],
      slocation: ["", Validators.required],
      internalusers: ["", Validators.required],
      externalusers: ["", Validators.required],
      describePS: [null],
    });
  }



  ngOnInit() {
    this.dropdownList = [
      { item_id: "1", item_text: 'USA' },
      { item_id: "2", item_text: 'Out Of USA' },
    ];

    this.slocation_dropdownList = [
      { item_id: "1", item_text: 'INDG offices or facilities (includes corporate offices, news bureaus, data centers warehouses, or other official INDG locations)' },
      { item_id: "2", item_text: 'Vendor offices or facilities (corporate offices, warehouses, data centers, or other subcontractor locations)' },
      { item_id: "3", item_text: 'INDG Client Locations' },
      { item_id: "4", item_text: 'Other (describe)' },
    ];

    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true,
    };

    let vlocationValue = localStorage.getItem("vlocationValue");
    let ulocationValue = localStorage.getItem("ulocationValue");
    let slocationValue = localStorage.getItem("slocationValue");
    let vlocation = localStorage.getItem("vlocation");
    let ulocation = localStorage.getItem("ulocation");
    let slocation = localStorage.getItem("slocation");
    let internalusers = localStorage.getItem("internalusers");
    let externalusers = localStorage.getItem("externalusers");
    let describePS = localStorage.getItem("describePS");


    if (vlocation !== null && ulocation !== null && slocation !== null && internalusers !== null && externalusers !== null
      && vlocationValue !== null && ulocationValue !== null && slocationValue !== null) {

      let vlocationFinalArray: any[] = [];
      let ulocationFinalArray: any[] = [];
      let slocationFinalArray: any[] = [];

      let vlocationValueArray: any[] = [];
      let ulocationValueArray: any[] = [];
      let slocationValueArray: any[] = [];

      let vlocationArray: any[] = [];
      let ulocationArray: any[] = [];

      let slocationArray: any[] = [];

      vlocationValueArray = vlocationValue.split(',')
      ulocationValueArray = ulocationValue.split(',')
      slocationValueArray = slocationValue.split(':')
      vlocationArray = vlocation.split(',')
      ulocationArray = ulocation.split(',')
      slocationArray = slocation.split(',')

      for (let i = 0; i < vlocationArray.length; i++) {
        vlocationFinalArray.push({ item_id: vlocationArray[i], item_text: vlocationValueArray[i] });
      }

      for (let i = 0; i < ulocationArray.length; i++) {
        ulocationFinalArray.push({ item_id: ulocationArray[i], item_text: ulocationValueArray[i] });
      }

      for (let i = 0; i < slocationArray.length; i++) {
        slocationFinalArray.push({ item_id: slocationArray[i], item_text: slocationValueArray[i] });
      }


      console.log(vlocationFinalArray);
      console.log(ulocationFinalArray);
      console.log(slocationFinalArray);
      console.log(internalusers);
      console.log(externalusers);
      console.log(describePS);

      if (describePS !== null && describePS !== 'null' && describePS !== '' && describePS !== undefined) {
        this.requireDescribe = true;
        this.securityvendorQuestionnaireForm = this.formBuilder.group({
          vlocation: [vlocationFinalArray, Validators.required],
          ulocation: [ulocationFinalArray, Validators.required],
          slocation: [slocationFinalArray, Validators.required],
          internalusers: [internalusers, Validators.required],
          externalusers: [externalusers, Validators.required],
          describePS: [describePS, Validators.required],
        });
      } else {
        this.requireDescribe = false;
        this.securityvendorQuestionnaireForm = this.formBuilder.group({
          vlocation: [vlocationFinalArray, Validators.required],
          ulocation: [ulocationFinalArray, Validators.required],
          slocation: [slocationFinalArray, Validators.required],
          internalusers: [internalusers, Validators.required],
          externalusers: [externalusers, Validators.required],
          describePS: [null],
        });
      }
    }
  }

  // Vendor & Users Location
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  // Service Location
  onItemSelectServiceLocation(item: any) {
    console.log('onItemSelect', item);
    if (item.item_id === '4') {
      this.requireDescribe = true;
      this.securityvendorQuestionnaireForm.controls['describePS'].setValidators(Validators.required);
    }

  }
  onItemDeSelectServiceLocation(item: any) {
    console.log('onItemDeSelect', item);
    if (item.item_id === '4') {
      this.requireDescribe = false;
      this.securityvendorQuestionnaireForm.controls['describePS'].clearValidators();
      this.securityvendorQuestionnaireForm.controls['describePS'].updateValueAndValidity();
      this.securityvendorQuestionnaireForm.controls['describePS'].reset();
      localStorage.setItem("describePS", '');
    }
  }
  onSelectAllServiceLocation(items: any) {
    console.log('onSelectAll', items);
    this.requireDescribe = true;
    this.securityvendorQuestionnaireForm.controls['describePS'].setValidators(Validators.required);
  }
  onUnSelectAllServiceLocation() {
    console.log('onUnSelectAll fires');
    this.requireDescribe = false;
    this.securityvendorQuestionnaireForm.controls['describePS'].clearValidators();
    this.securityvendorQuestionnaireForm.controls['describePS'].updateValueAndValidity();
    this.securityvendorQuestionnaireForm.controls['describePS'].reset();
    localStorage.setItem("describePS", '');
  }

  onSubmit(): void {
    console.warn('Form Values', this.securityvendorQuestionnaireForm.value);
    if (this.securityvendorQuestionnaireForm.valid) {

      let vendorLocationID = '';
      let vendorLocationValue = '';
      const vlocationArray: any[] = this.securityvendorQuestionnaireForm.value.vlocation;
      vlocationArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (vendorLocationID !== '') {
            vendorLocationID = vendorLocationID + ',' + item.item_id;
          } else {
            vendorLocationID = item.item_id;
          }
          if (vendorLocationValue !== '') {
            vendorLocationValue = vendorLocationValue + ',' + item.item_text;
          } else {
            vendorLocationValue = item.item_text;
          }
        }
      })

      let userLocationID = '';
      let userLocationValue = '';
      const ulocationArray: any[] = this.securityvendorQuestionnaireForm.value.ulocation;
      ulocationArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (userLocationID !== '') {
            userLocationID = userLocationID + ',' + item.item_id;
          } else {
            userLocationID = item.item_id;
          }
          if (userLocationValue !== '') {
            userLocationValue = userLocationValue + ',' + item.item_text;
          } else {
            userLocationValue = item.item_text;
          }
        }
      })

      let serviceLocationID = '';
      let serviceLocationValue = '';
      const slocationArray: any[] = this.securityvendorQuestionnaireForm.value.slocation;
      slocationArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (serviceLocationID !== '') {
            serviceLocationID = serviceLocationID + ',' + item.item_id;
          } else {
            serviceLocationID = item.item_id;
          }
          if (serviceLocationValue !== '') {
            serviceLocationValue = serviceLocationValue + ':' + item.item_text;
          } else {
            serviceLocationValue = item.item_text;
          }
        }
      })

      localStorage.setItem("vlocation", vendorLocationID);
      localStorage.setItem("ulocation", userLocationID);
      localStorage.setItem("slocation", serviceLocationID);
      localStorage.setItem("vlocationValue", vendorLocationValue);
      localStorage.setItem("ulocationValue", userLocationValue);
      localStorage.setItem("slocationValue", serviceLocationValue);
      localStorage.setItem("internalusers", this.securityvendorQuestionnaireForm.value.internalusers);
      localStorage.setItem("externalusers", this.securityvendorQuestionnaireForm.value.externalusers);
      if (this.requireDescribe) {
        localStorage.setItem("describePS", this.securityvendorQuestionnaireForm.value.describePS);
      }
      this.router.navigate(['../vendor-questionnaire-resiliency-continuity'], { relativeTo: this.route });
    }
    /*  if (this.securityvendorForm.valid) {
        this.pushDetailsToJIRA(this.securityvendorForm.value);
      } */
  }

}