import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { OktaService } from '../authenticate/okta.service';

@Component({
  selector: 'vendor-details.component',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css']
})
export class VendorDetailsComponent implements OnInit {
  securityvendorForm: FormGroup;
  salesflag: boolean = false;
  editorialflag: boolean = false;
  additionalflag: boolean = false;
  ticket: any;
  employeeName: any;
  employeeId: any;
  data: any[] = [];
  users: any[] = [];
  errorMessage: any;
  hrEmployeeType: any[] = [];
  hireType: any[] = [];
  phoneDetails: any[] = [];
  workSpaceLocation: any[] = [];
  workSpaceImprovements: any[] = [];
  computingEquipments: any[] = [];
  applicationGroup: any[] = [];
  amexCardRequired: any[] = [];
  newSalesForceAccount: any[] = [];
  salesForceUserType: any[] = [];
  editorialPersona: any[] = [];
  bwriteRole: any[] = [];
  user: any;
  password: any;
  showBWrite: boolean = false;
  auth: any;
  userName: string = "";
  jiraError: any = 'no';
  scopeChanged: boolean = false;
  mySwitch : boolean = false;
  submit : boolean = false;

  constructor(private oktaService: OktaService, private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.securityvendorForm = this.formBuilder.group({
      busunit: [null, Validators.required],
      busowner: [null, Validators.required],
      vname: [null, Validators.required],
      contact: [null, Validators.required],
      evendor: ["", Validators.required],
      scope: [""],
      engagement: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.scopeChanged = false;
    this.auth = "";
    let claims: any = this.oktaService.getClaims();
    let user = '';
    if (claims) {
      console.log('Claims', claims);
      user = claims['preferred_username'];
      let authToken = claims['name'];
      console.log("Auth token ", authToken);
      localStorage.setItem("Auth", authToken);
    }
    if (user !== '') {
      let str = user.split("@");
      if (str.length > 0) {
        this.userName = str[0];
      }
    }
    console.log('Username', this.userName);
    let currentURL = window.location.href;
    if (currentURL.toString().includes("submit")) {
      this.submit = true;
    } else {
      this.submit = false;
    }
    this.validateLoginUserName(this.userName);
    let busunit = localStorage.getItem("busunit");
    let busowner = localStorage.getItem("busowner");
    let vname = localStorage.getItem("vname");
    let contact = localStorage.getItem("contact");
    let evendor = localStorage.getItem("evendor");
    let scope = localStorage.getItem("scope");
    let engagement = localStorage.getItem("engagement");


    if (vname !== null && contact !== null && evendor !== null && engagement !== null) {
      this.securityvendorForm = this.formBuilder.group({
        busunit: [busunit, Validators.required],
        busowner: [busowner, Validators.required],
        vname: [vname, Validators.required],
        contact: [contact, Validators.required],
        evendor: [evendor, Validators.required],
        scope: [scope, Validators.required],
        engagement: [engagement, Validators.required]
      });
    }



    if (evendor == "1") {
      this.scopeChanged = true;
      this.securityvendorForm.controls['scope'].setValidators(Validators.required);
    } else {
      this.scopeChanged = false;
      this.securityvendorForm.controls['scope'].clearValidators();
      this.securityvendorForm.controls['scope'].updateValueAndValidity();
      this.securityvendorForm.controls['scope'].reset();
    }


  }



  getExistingVendor(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("evendor", event.target.value)
      if (event.target.value == "1") {
        this.scopeChanged = true;
        this.securityvendorForm.controls['scope'].setValidators(Validators.required);
      } else {
        this.scopeChanged = false;
        this.securityvendorForm.controls['scope'].clearValidators();
        this.securityvendorForm.controls['scope'].updateValueAndValidity();
        this.securityvendorForm.controls['scope'].reset();
        localStorage.setItem("scope", '');
      }
    }
  }

  getScopeChange(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("scope", event.target.value)
    } else {
      localStorage.setItem("scope", '');
    }
  }


  resetForm() {
    if (this.mySwitch === true) {
      this.securityvendorForm.reset();
      localStorage.clear();
    /*  localStorage.setItem("vname", null);
      localStorage.setItem("evendor", "");
      localStorage.setItem("scope", "");
      localStorage.setItem("engagement","");
      localStorage.setItem("vlocation", "");
      localStorage.setItem("ulocation", "");
      localStorage.setItem("slocation", "");
      localStorage.setItem("internalusers", "");
      localStorage.setItem("externalusers", "");
      localStorage.setItem("pdelivery", "");
      localStorage.setItem("bprocess", "");
      localStorage.setItem("rpobjective", "");
      localStorage.setItem("rtobjective", "");
      localStorage.setItem("tusage", "");
      localStorage.setItem("dtypes", "");
      localStorage.setItem("pdatacollection", "");
      localStorage.setItem("dlocation", "");
      localStorage.setItem("dsharing", "");
      localStorage.setItem("oadevelopment", "");
      localStorage.setItem("dvolume", "");
      localStorage.setItem("dataset", "");
      localStorage.setItem("susage", "");
      localStorage.setItem("smaintenance", "");
      localStorage.setItem("saccess", "");
      localStorage.setItem("shosting", "");
      localStorage.setItem("sauthentication", "");
      localStorage.setItem("dtransmission", "");
      localStorage.setItem("sintegration","");
      localStorage.setItem("sreview","");
      localStorage.setItem("cwebsite", "");
      localStorage.setItem("ccustomers", "");
      localStorage.setItem("subcontractors", "");   */
      this.scopeChanged = false;
    }
  }





  validateLoginUserName(user: string) {
    this.auth = localStorage.getItem("Auth");
    console.log("Storage Auth ", this.auth);
    localStorage.setItem("loginUser", user);
    const headers = {
      "Authorization": 'Basic ' + this.auth,
      "Access-Control-Allow-Origin": "*"
    };
    const params = 'user?username=' + user + '&expand=groups';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.jiraError = 'no';
          console.log('data ', data);
          console.log('Logged in Successfully ', data.displayName);
          localStorage.setItem("username", data.displayName);
        },
        error: error => {
          this.jiraError = 'yes';
          console.log('Error ', error);
          this.errorMessage = error.message;
        }
      })
  }



  onSubmit(): void {
    console.warn('Form Values', this.securityvendorForm.value);
    console.log(this.securityvendorForm.valid);
    if (this.securityvendorForm.valid) {
      localStorage.setItem("busunit", this.securityvendorForm.value.busunit);
      localStorage.setItem("busowner", this.securityvendorForm.value.busowner);
      localStorage.setItem("vname", this.securityvendorForm.value.vname);
      localStorage.setItem("contact", this.securityvendorForm.value.contact);
      //   localStorage.setItem("evendor", this.securityvendorForm.value.evendor);
      //    localStorage.setItem("scope", this.securityvendorForm.value.scope);
      localStorage.setItem("engagement", this.securityvendorForm.value.engagement);
      this.router.navigate(['../vendor-questionnaire'], { relativeTo: this.route });
    }
    /*  if (this.securityvendorForm.valid) {
        this.pushDetailsToJIRA(this.securityvendorForm.value);
      } */
  }
}