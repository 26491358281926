import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment'
import { HttpClient, HttpParams } from '@angular/common/http';
import { VirtualTimeScheduler } from 'rxjs';


@Component({
  selector: 'reputational-page.component',
  templateUrl: './reputational-page.component.html',
  styleUrls: ['./reputational-page.component.css']
})



export class ReputationalPageComponent {
  username: string = '';
  password: string = '';
  ResiliencyForm: FormGroup;
  errorMessage: any;
  unAuthorizedUser: boolean = false;
  validateAdmin: boolean = false;
  percentage = 95;
  auth: any;
  virascore: any;
  level: any;
  user: any;
  softwareReview : boolean = false;
  submitted : boolean = false;
  //multiVendorLocation: any;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.ResiliencyForm = this.formBuilder.group({
      ccustomers: ["", Validators.required],
      subcontractors: ["", Validators.required]
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.user = localStorage.getItem("loginUser");
    this.virascore = 0;
    this.level = "";
    let ccustomers = localStorage.getItem("ccustomers");
    let subcontractors = localStorage.getItem("subcontractors");

    if (ccustomers !== null && subcontractors !== null) {
      this.ResiliencyForm = this.formBuilder.group({
        ccustomers: [ccustomers, Validators.required],
        subcontractors: [subcontractors, Validators.required]
      });
    }
    if (ccustomers !== null && ccustomers !== '' && ccustomers !== undefined && subcontractors !== '' && subcontractors !== undefined && subcontractors !== null) {
      this.percentage = 100;
    }
  }


  getcustomers(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("ccustomers", event.target.value)
    }
  }

  getsubcontractors(event: any) {
    console.log('Event ', event.target.value);
    if (event !== "" && event !== null && event !== undefined) {
      localStorage.setItem("subcontractors", event.target.value)
      this.percentage = 100;
    }
  }



  onSubmit() {
    if (this.ResiliencyForm.valid) {
      this.percentage = 100
      this.submitted = true;
      console.log('Software Usage', localStorage.getItem("susage"));
      if (localStorage.getItem("susage") === '1') {
        this.softwareReview = true;
      }
      //  if (confirm("Please review your responses by clicking previous buttons. Once submitted, you cannot review it. If you are sure to submit it, click OK")) {
      if (confirm("Please review each response prior to submitting to ensure accuracy and completion. If you have any questions, please contact the Risk Assurance team.")) {
        var viraScore = 0;
        console.log(localStorage.getItem("vname"));
        console.log(localStorage.getItem("contact"));
        console.log(localStorage.getItem("evendor"));
        console.log(localStorage.getItem("scope"));
        console.log(localStorage.getItem("engagement"));
     //   console.log(localStorage.getItem("sreview"));
        console.log(localStorage.getItem("busowner"));
        console.log(localStorage.getItem("busunit"));
        console.log(localStorage.getItem("cwebsite"));
        console.log(localStorage.getItem("sname"));
        console.log(localStorage.getItem("edition"));
        console.log(localStorage.getItem("vlocation"));
        console.log(localStorage.getItem("ulocation"));;
        console.log(localStorage.getItem("slocation"));
        console.log(localStorage.getItem("internalusers"));
        console.log(localStorage.getItem("externalusers"));
        console.log(localStorage.getItem("pdelivery"));
        console.log(localStorage.getItem("bprocess"));
        console.log(localStorage.getItem("rpobjective"));
        console.log(localStorage.getItem("rtobjective"));
        console.log(localStorage.getItem("llmuse"));;
        console.log(localStorage.getItem("tusage"));;
        console.log(localStorage.getItem("dtypes"));
        console.log(localStorage.getItem("pdatacollection"));
        console.log(localStorage.getItem("dlocation"));
        console.log(localStorage.getItem("dsharing"));
        console.log(localStorage.getItem("oadevelopment"));
        console.log(localStorage.getItem("dvolume"));
        console.log(localStorage.getItem("dataset"));
        console.log(localStorage.getItem("intpropdtypes"));
        console.log(localStorage.getItem("otherIntpropdaccess"));
        console.log(localStorage.getItem("susage"));
        console.log(localStorage.getItem("smaintenance"));
        console.log(localStorage.getItem("saccess"));
        console.log(localStorage.getItem("shosting"));
        console.log(localStorage.getItem("sauthentication"));
        console.log(localStorage.getItem("dtransmission"));
        console.log(localStorage.getItem("sintegration"));
        console.log(localStorage.getItem("ccustomers"));
        console.log(localStorage.getItem("subcontractors"));

        console.log(localStorage.getItem("describePS"));
        console.log(localStorage.getItem("describePD"));
        console.log(localStorage.getItem("describePT"));
        console.log(localStorage.getItem("describeSI"));
        console.log(localStorage.getItem("describeCE"));
        console.log(localStorage.getItem("othersoftware"));

        let vlocation = localStorage.getItem("vlocation");
        let vlocationArray: any[] = [];
        if (vlocation !== null) {
          vlocationArray = vlocation.split(',');
        }
        console.log('Vendor Location ', vlocationArray)
        if (vlocationArray != null && vlocationArray.length > 0) {
          var value2Exists = vlocationArray.filter(x => x == '2')
          if (value2Exists != null && value2Exists.length > 0) {
            //     this.multiVendorLocation = "Yes";
            viraScore = viraScore + 2;
          } else {
            //     this.multiVendorLocation = "No";
            viraScore = viraScore + 0.4;
          }
        }
        console.log('VIRA Score ', viraScore);


        let ulocation = localStorage.getItem("ulocation");
        let ulocationArray: any[] = [];
        if (ulocation !== null) {
          ulocationArray = ulocation.split(',');
        }
        console.log('User Location ', ulocationArray)
        if (ulocationArray != null && ulocationArray.length > 0) {
          var value2Exists = ulocationArray.filter(x => x == '2')
          if (value2Exists != null && value2Exists.length > 0) {
            viraScore = viraScore + 1;
          } else {
            viraScore = viraScore + 0.2;
          }
        }
        console.log('VIRA Score ', viraScore);

        let slocation = localStorage.getItem("slocation");
        let slocationArray: any[] = [];
        if (slocation !== null) {
          slocationArray = slocation.split(',');
        }
        console.log('Service Location ', slocationArray)
        if (slocationArray != null && slocationArray.length > 0) {
          var value4Exists = slocationArray.filter(x => x == '4')
          var value2or3Exists = slocationArray.filter(x => x == '2' || x == '3')
          var value1Exists = slocationArray.filter(x => x == '1')
          if (value4Exists != null && value4Exists.length > 0) {
            viraScore = viraScore + 5;
          } else if (value2or3Exists != null && value2or3Exists.length > 0) {
            viraScore = viraScore + 4;
          } else if (value1Exists != null && value1Exists.length > 0) {
            viraScore = viraScore + 1;
          }
        }
        console.log('VIRA Score ', viraScore);







        var iusers = localStorage.getItem("internalusers");
        console.log('Internal Users ', iusers)
        if (iusers != null && iusers != '') {
          if (iusers == '5') {
            viraScore = viraScore + 1;
          } else if (iusers == '4') {
            viraScore = viraScore + 0.8;
          } else if (iusers == '3') {
            viraScore = viraScore + 0.6;
          } else if (iusers == '2') {
            viraScore = viraScore + 0.4;
          } else if (iusers == '1') {
            viraScore = viraScore + 0.2;
          }
        }
        console.log('VIRA Score ', viraScore);

        var eusers = localStorage.getItem("externalusers");
        console.log('External Users ', eusers)
        if (eusers != null && eusers != '') {
          if (eusers == '5') {
            viraScore = viraScore + 1;
          } else if (eusers == '4') {
            viraScore = viraScore + 0.8;
          } else if (eusers == '3') {
            viraScore = viraScore + 0.6;
          } else if (eusers == '2') {
            viraScore = viraScore + 0.4;
          } else if (eusers == '1') {
            viraScore = viraScore + 0.2;
          }
        }
        console.log('VIRA Score ', viraScore);

        var pdelivery = localStorage.getItem("pdelivery");
        console.log('Product Delivery ', pdelivery)
        if (pdelivery != null && pdelivery != '') {
          if (pdelivery == '4') {
            viraScore = viraScore + 3;
          } else if (pdelivery == '3') {
            viraScore = viraScore + 2.4;
          } else if (pdelivery == '2') {
            viraScore = viraScore + 1.8;
          } else if (pdelivery == '1') {
            viraScore = viraScore + 0.6;
          }
        }
        console.log('VIRA Score ', viraScore);

        var bprocess = localStorage.getItem("bprocess");
        console.log('Business Process ', bprocess)
        if (bprocess != null && bprocess != '') {
          if (bprocess == '4') {
            viraScore = viraScore + 3;
          } else if (bprocess == '3') {
            viraScore = viraScore + 2.4;
          } else if (bprocess == '2') {
            viraScore = viraScore + 1.8;
          } else if (bprocess == '1') {
            viraScore = viraScore + 0.6;
          }
        }
        console.log('VIRA Score ', viraScore);

        var rpobjective = localStorage.getItem("rpobjective");
        console.log('Recovery Point Objective ', rpobjective)
        if (rpobjective != null && rpobjective != '') {
          if (rpobjective == '6' || rpobjective == '5') {
            viraScore = viraScore + 2;
          } else if (rpobjective == '4') {
            viraScore = viraScore + 1.6;
          } else if (rpobjective == '3') {
            viraScore = viraScore + 1.2;
          } else if (rpobjective == '2') {
            viraScore = viraScore + 0.8;
          } else if (rpobjective == '1') {
            viraScore = viraScore + 0.4;
          }
        }
        console.log('VIRA Score ', viraScore);

        var rtobjective = localStorage.getItem("rtobjective");
        console.log('Recovery Time Objective ', rtobjective)
        if (rtobjective != null && rtobjective != '') {
          if (rtobjective == '4') {
            viraScore = viraScore + 0.4;
          } else if (rtobjective == '3') {
            viraScore = viraScore + 1.2;
          } else if (rtobjective == '2') {
            viraScore = viraScore + 1.6;
          } else if (rtobjective == '1') {
            viraScore = viraScore + 2;
          }
        }
        console.log('VIRA Score ', viraScore);


        var llmuse = localStorage.getItem("llmuse");
        console.log('LLM/GenAI Use', llmuse)
        if (llmuse != null && llmuse != '') {
          if (llmuse == '1') {
            viraScore = viraScore + 12;
          }
        }

        console.log('VIRA Score ', viraScore);

        var tusage = localStorage.getItem("tusage");
        console.log('Technology Usage ', tusage)
        if (tusage != null && tusage != '') {
          if (tusage == '1') {
            viraScore = viraScore + 2;
          } else if (tusage == '2') {
            viraScore = viraScore + 0.4;
          }
        }
        console.log('VIRA Score ', viraScore);



        let dtypes = localStorage.getItem("dtypes");
        let dtypesArray: any[] = [];
        if (dtypes !== null) {
          dtypesArray = dtypes.split(',');
        }
        console.log('Data Types ', dtypesArray)
        if (dtypesArray != null && dtypesArray.length > 0) {
          var fifteenExists = dtypesArray.filter(x => x == '1' || x == '2' || x == '4')
          var twelveExists = dtypesArray.filter(x => x == '5' || x == '6')
          var nineExists = dtypesArray.filter(x => x == '3' || x == '7' || x == '11')
          var sixExists = dtypesArray.filter(x => x == '8' || x == '9' || x == '10')
          var threeExists = dtypesArray.filter(x => x == '12' || x == '13' || x == '14')
          if (fifteenExists != null && fifteenExists.length > 0) {
            viraScore = viraScore + 15;
          } else if (twelveExists != null && twelveExists.length > 0) {
            viraScore = viraScore + 12;
          } else if (nineExists != null && nineExists.length > 0) {
            viraScore = viraScore + 9;
          } else if (sixExists != null && sixExists.length > 0) {
            viraScore = viraScore + 6;
          } else if (threeExists != null && threeExists.length > 0) {
            viraScore = viraScore + 3;
          }
        }
        console.log('VIRA Score ', viraScore);


        let pdatacollection = localStorage.getItem("pdatacollection");
        let pdatacollectionArray: any[] = [];
        if (pdatacollection !== null) {
          pdatacollectionArray = pdatacollection.split(',');
        }
        console.log('Personal Data Collection ', pdatacollectionArray)
        if (pdatacollectionArray != null && pdatacollectionArray.length > 0) {
          var twelveExists = pdatacollectionArray.filter(x => x == '8' || x == '9' || x == '10' || x == '11' || x == '13' || x == '14' || x == '15')
          var nineExists = pdatacollectionArray.filter(x => x == '4' || x == '5' || x == '7' || x == '12' || x == '18')
          var sevenExists = pdatacollectionArray.filter(x => x == '1' || x == '2' || x == '3' || x == '6' || x == '16' || x == '17' || x == '19' || x == '20' || x == '21')
          if (twelveExists != null && twelveExists.length > 0) {
            viraScore = viraScore + 12;
          } else if (nineExists != null && nineExists.length > 0) {
            viraScore = viraScore + 9.6;
          } else if (sevenExists != null && sevenExists.length > 0) {
            viraScore = viraScore + 7.2;
          }
        }
        console.log('VIRA Score ', viraScore);


        var dlocation = localStorage.getItem("dlocation");
        console.log('Data Location ', dlocation)
        if (dlocation != null && dlocation != '') {
          if (dlocation == '1') {
            viraScore = viraScore + 4;
          } else if (dlocation == '2') {
            viraScore = viraScore + 0.8;
          }
        }
        console.log('VIRA Score ', viraScore);



        var dvolume = localStorage.getItem("dvolume");
        console.log('Data Volume ', dvolume)
        if (dvolume != null && dvolume != '') {
          if (dvolume == '5') {
            viraScore = viraScore + 2;
          } else if (dvolume == '4') {
            viraScore = viraScore + 1.6;
          } else if (dvolume == '3') {
            viraScore = viraScore + 1.2;
          } else if (dvolume == '2') {
            viraScore = viraScore + 0.8;
          } else if (dvolume == '1') {
            viraScore = viraScore + 0.4;
          }
        }
        console.log('VIRA Score ', viraScore);


        var dataset = localStorage.getItem("dataset");
        console.log('Data Set ', dataset)
        console.log('VIRA Score ', viraScore);



        var dsharing = localStorage.getItem("dsharing");
        console.log('Data Sharing ', dsharing)
        if (dsharing != null && dsharing != '') {
          if (dsharing == '1') {
            viraScore = viraScore + 5;
          } else if (dsharing == '2') {
            viraScore = viraScore + 1;
          }
        }
        console.log('VIRA Score ', viraScore);


        var oadevelopment = localStorage.getItem("oadevelopment");
        console.log('Outsourced Application Development ', oadevelopment)
        if (oadevelopment != null && oadevelopment != '') {
          if (oadevelopment == '1') {
            viraScore = viraScore + 5;
          } else if (oadevelopment == '2') {
            viraScore = viraScore + 1;
          }
        }
        console.log('VIRA Score ', viraScore);



        let dtransmission = localStorage.getItem("dtransmission");
        let dtransmissionArray: any[] = [];
        if (dtransmission !== null) {
          dtransmissionArray = dtransmission.split(',');
        }
        console.log('Data Transmission ', dtransmissionArray)
        if (dtransmissionArray != null && dtransmissionArray.length > 0) {
          var twelveExists = dtransmissionArray.filter(x => x == '2' || x == '3')
          var sevenExists = dtransmissionArray.filter(x => x == '4' || x == '5')
          var fourExists = dtransmissionArray.filter(x => x == '6')
          var twoExists = dtransmissionArray.filter(x => x == '1')

          if (twelveExists != null && twelveExists.length > 0) {
            viraScore = viraScore + 12;
          } else if (sevenExists != null && sevenExists.length > 0) {
            viraScore = viraScore + 7.2;
          } else if (fourExists != null && fourExists.length > 0) {
            viraScore = viraScore + 4.8;
          } else if (twoExists != null && twoExists.length > 0) {
            viraScore = viraScore + 2.4;
          }
        }
        console.log('VIRA Score ', viraScore);



        let sintegration = localStorage.getItem("sintegration");
        let sintegrationArray: any[] = [];
        if (sintegration !== null) {
          sintegrationArray = sintegration.split(',');
        }
        console.log('Service Integration ', sintegrationArray)
        if (sintegrationArray != null && sintegrationArray.length > 0) {
          var eightExists = sintegrationArray.filter(x => x == '1' || x == '2')
          var fourExists = sintegrationArray.filter(x => x == '5' || x == '6' || x == '7')
          var threeExists = sintegrationArray.filter(x => x == '3' || x == '9')
          var oneExists = sintegrationArray.filter(x => x == '4')

          if (eightExists != null && eightExists.length > 0) {
            viraScore = viraScore + 8;
          } else if (fourExists != null && fourExists.length > 0) {
            viraScore = viraScore + 4.8;
          } else if (threeExists != null && threeExists.length > 0) {
            viraScore = viraScore + 3.2;
          } else if (oneExists != null && oneExists.length > 0) {
            viraScore = viraScore + 1.6;
          }
        }
        console.log('VIRA Score ', viraScore);



        let saccess = localStorage.getItem("saccess");
        let saccessArray: any[] = [];
        if (saccess !== null) {
          saccessArray = saccess.split(',');
        }
        console.log('System Access ', saccessArray)
        if (saccessArray != null && saccessArray.length > 0) {
          var fiveExists = saccessArray.filter(x => x == '3')
          var fourExists = saccessArray.filter(x => x == '2')
          var threeExists = saccessArray.filter(x => x == '1')
          var twoExists = saccessArray.filter(x => x == '4')
          var oneExists = saccessArray.filter(x => x == '5')

          if (fiveExists != null && fiveExists.length > 0) {
            viraScore = viraScore + 5;
          } else if (fourExists != null && fourExists.length > 0) {
            viraScore = viraScore + 4;
          } else if (threeExists != null && threeExists.length > 0) {
            viraScore = viraScore + 3;
          } else if (twoExists != null && twoExists.length > 0) {
            viraScore = viraScore + 2;
          } else if (oneExists != null && oneExists.length > 0) {
            viraScore = viraScore + 1;
          }
        }
        console.log('VIRA Score ', viraScore);








        var susage = localStorage.getItem("susage");
        console.log('System Usage ', susage)
        if (susage != null && susage != '') {
          if (susage == '1') {
            viraScore = viraScore + 3;
          } else if (susage == '2') {
            viraScore = viraScore + 0.6;
          }
        }
        console.log('VIRA Score ', viraScore);


        var sauthentication = localStorage.getItem("sauthentication");
        console.log('Software Authentication ', sauthentication)
        if (sauthentication != null && sauthentication != '') {
          if (sauthentication == '1') {
            viraScore = viraScore + 1.2;
          } else if (sauthentication == '2') {
            viraScore = viraScore + 3;
          } else if (sauthentication == '3') {
            viraScore = viraScore + 0.6;
          }
        }
        console.log('VIRA Score ', viraScore);


        var shosting = localStorage.getItem("shosting");
        console.log('Software Hosting ', shosting)
        if (shosting != null && shosting != '') {
          if (shosting == '3') {
            viraScore = viraScore + 1;
          } else if (shosting == '2') {
            viraScore = viraScore + 0.8;
          } else if (shosting == '1') {
            viraScore = viraScore + 0.4;
          } else if (shosting == '4') {
            viraScore = viraScore + 0.2;
          }
        }
        console.log('VIRA Score ', viraScore);



        var smaintenance = localStorage.getItem("smaintenance")
        console.log('Software Maintenance ', smaintenance)
        if (smaintenance != null && smaintenance != '') {
          if (smaintenance == '2') {
            viraScore = viraScore + 1;
          } else if (smaintenance == '1') {
            viraScore = viraScore + 0.4;
          } else if (smaintenance == '3') {
            viraScore = viraScore + 0.2;
          }
        }
        console.log('VIRA Score ', viraScore);



        var customers = localStorage.getItem("ccustomers")
        console.log('Contact with Customers ', customers)
        if (customers != null && customers != '') {
          if (customers == '1') {
            viraScore = viraScore + 2.5;
          } else if (customers == '2') {
            viraScore = viraScore + 0.5;
          }
        }
        console.log('VIRA Score ', viraScore);



        var subcontractor = localStorage.getItem("subcontractors")
        console.log('Use of subcontractors ', subcontractor)
        if (subcontractor != null && subcontractor != '') {
          if (subcontractor == '1') {
            viraScore = viraScore + 2.5;
          } else if (subcontractor == '2') {
            viraScore = viraScore + 0.5;
          }
        }
        console.log('VIRA Score ', viraScore);
        this.virascore = viraScore;
        var level = '';
        if (viraScore < 21) {
          level = 'Very Low';
        } else if (viraScore >= 21 && viraScore < 41) {
          level = 'Low';
        } else if (viraScore >= 41 && viraScore < 61) {
          level = 'Moderate';
        } else if (viraScore >= 61 && viraScore < 81) {
          level = 'High';
        } else if (viraScore >= 81) {
          level = 'Very High';
        }
        console.log('Level ', level);
        this.level = level;

        this.pushDetailsToJIRA();
        //  this.router.navigate(['../vendor-details-submit'], { relativeTo: this.route });

        //  localStorage.clear();
        //  this.ResiliencyForm.reset();

      }
    }
  }


  pushDetailsToJIRA() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload();
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            console.log('softwareReview' , this.softwareReview);
            if (this.softwareReview) {
                this.pushDetailsToJIRA_SoftwareReview()
            } else {
              this.router.navigate(['../vendor-details-submit'], { relativeTo: this.route });
              localStorage.clear();
            }
          } else {
            alert("An Error occured while submitting form details to Jira, please submit a service desk ticket");
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }

  pushDetailsToJIRA_SoftwareReview() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload_SoftwareReview();
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            this.router.navigate(['../vendor-details-submit'], { relativeTo: this.route });
            localStorage.clear();
          } else {
            alert("An Error occured while submitting form details to Jira, please submit a service desk ticket");
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }





  formPayload() {

    let fields: any = {};

    let project = { id: "12501" };
    fields.project = project;

    let issuetype = { id: "10203" };
    fields.issuetype = issuetype;

    let assignee = { name: "Security Compliance Team" };
    fields.assignee = assignee;

    let reporter = { name: this.user };
    fields.reporter = reporter;

    let priority = { id: "4" };
    fields.priority = priority;

    let components = [{ id: "23203" }];
    fields.components = components;

    let labels = ["vendor_review"];
    fields.labels = labels;

    let security = { id: "11300" };
    fields.security = security;

    let busowner = localStorage.getItem("busowner");
    console.log('busowner', busowner);
    let businessOwner = '';
    if (busowner !== null && busowner !== undefined && busowner != "") {
      if (busowner == "1") {
        businessOwner = "Marketing"
      } else if (busowner == "2") {
        businessOwner = "Operations"
      } else if (busowner == "3") {
        businessOwner = "Sales"
      } else if (busowner == "4") {
        businessOwner = "Professional Services"
      } else if (busowner == "5") {
        businessOwner = "Customer Support"
      } else if (busowner == "6") {
        businessOwner = "BNA"
      } else if (busowner == "7") {
        businessOwner = "Product Management"
      } else if (busowner == "8") {
        businessOwner = "Customer Experience"
      } else if (busowner == "9") {
        businessOwner = "Internal Audit"
      } else if (busowner == "10") {
        businessOwner = "Pricing"
      } else if (busowner == "11") {
        businessOwner = "TBD"
      }
      let customfield_42800 = { value: businessOwner }
      fields.customfield_42800 = customfield_42800;
    }

    let busUnit = localStorage.getItem("busunit");
    if (busUnit !== null && busUnit !== undefined && busUnit != "") {
      let customfield_58800 = busUnit;
      fields.customfield_58800 = customfield_58800;
    }

    let vendorName = localStorage.getItem("vname");
    if (vendorName !== null && vendorName !== undefined && vendorName != "") {
      let customfield_29510 = vendorName;
      fields.customfield_29510 = customfield_29510;
    }

    let contact = localStorage.getItem("contact");
    if (contact !== null && contact !== undefined && contact != "") {
      let customfield_29510_contact = contact;
      fields.customfield_29510 = fields.customfield_29510 + '\n' + customfield_29510_contact;
    }

    let summary = "Vendor Review Request for " + vendorName;
    fields.summary = summary;
    fields.description = summary;


    let evendor = localStorage.getItem("evendor");
    let vendorType = '';
    if (evendor !== null && evendor !== undefined && evendor != "") {
      if (evendor == "1") {
        vendorType = "Existing Vendor"
      } else if (evendor == "2") {
        vendorType = "New Vendor"
      }
      let customfield_56500 = { value: vendorType }
      fields.customfield_56500 = customfield_56500;
    }

    let scope = localStorage.getItem("scope");
    let scopeChanged = "";
    if (scope !== null && scope !== 'null' && scope !== undefined && scope != "") {
      if (scope == "1") {
        scopeChanged = "Yes"
      } else if (scope == "2") {
        scopeChanged = "No"
      }
      let customfield_58100 = { value: scopeChanged }
      fields.customfield_58100 = customfield_58100;
    }

    let scopeEngagement = localStorage.getItem("engagement");
    if (scopeEngagement !== null && scopeEngagement !== undefined && scopeEngagement != "") {
      let customfield_27400 = scopeEngagement;
      fields.customfield_27400 = customfield_27400;
    }

 /*   let sreview = localStorage.getItem("sreview");
    let softwareReview = "";
    if (sreview !== null && sreview !== undefined && sreview != "") {
      if (sreview == "1") {
        softwareReview = "Yes"
      } else if (sreview == "2") {
        softwareReview = "No"
      }
      let customfield_58101 = { value: softwareReview }
      fields.customfield_58101 = customfield_58101;
    } */

    let cwebsite = localStorage.getItem("cwebsite");
    if (cwebsite !== null && cwebsite !== 'null' && cwebsite !== undefined && cwebsite != "") {
      let customfield_32738 = '';
      if (cwebsite.toLocaleLowerCase().startsWith('https://')) {
         customfield_32738 = cwebsite;
      } else {
        customfield_32738 = 'https://' + cwebsite;
      }
      fields.customfield_32738 = customfield_32738;
    }

    let sname = localStorage.getItem("sname");
    if (sname !== null && sname !== 'null' && sname !== undefined && sname != "") {
      let customfield_68200 ;
      let sedition = localStorage.getItem("softwareedition");
      if (sedition !== null && sedition !== undefined && sedition !== 'null' && sedition !== '') {
        console.log('sedition ', sedition);
         customfield_68200 = { value: sname , child : {value: sedition} }
      }  else {
         customfield_68200 = { value: sname }
      }
      fields.customfield_68200 = customfield_68200;
    }


    /*  if (this.multiVendorLocation !== null && this.multiVendorLocation !== undefined && this.multiVendorLocation !== "") {
        let customfield_57603 =  {value: this.multiVendorLocation}
        fields.customfield_57603 = customfield_57603;
      } */



    let vlocation = localStorage.getItem("vlocation");
    if (vlocation !== null && vlocation !== undefined && vlocation != "") {
      let customfield_22803Array: any[] = [];
      let vlocationArray: any[] = [];
      vlocationArray = vlocation.split(',');
      if (vlocationArray != null && vlocationArray.length > 0) {
        var value1Exists = vlocationArray.filter(x => x == '1')
        var value2Exists = vlocationArray.filter(x => x == '2')
        if (value1Exists != null && value1Exists.length > 0) {
          let customfield_22803 = { value: "United States" };
          customfield_22803Array.push(customfield_22803);
        };
        if (value2Exists != null && value2Exists.length > 0) {
          let customfield_22803 = { value: "Out of USA" };
          customfield_22803Array.push(customfield_22803);
          let customfield_57603 = { value: "Yes" }
          fields.customfield_57603 = customfield_57603;
        };
        fields.customfield_22803 = customfield_22803Array;
      }
    }


    let ulocation = localStorage.getItem("ulocation");
    if (ulocation !== null && ulocation !== undefined && ulocation != "") {
      let customfield_67315Array: any[] = [];
      let ulocationArray: any[] = [];
      ulocationArray = ulocation.split(',');
      if (ulocationArray != null && ulocationArray.length > 0) {
        var value1Exists = ulocationArray.filter(x => x == '1')
        var value2Exists = ulocationArray.filter(x => x == '2')
        if (value1Exists != null && value1Exists.length > 0) {
          let customfield_67315 = { value: "USA" };
          customfield_67315Array.push(customfield_67315);
        };
        if (value2Exists != null && value2Exists.length > 0) {
          let customfield_67315 = { value: "Out of USA" };
          customfield_67315Array.push(customfield_67315);
        };
        fields.customfield_67315 = customfield_67315Array;
      }
    }


    let slocation = localStorage.getItem("slocation");
    if (slocation !== null && slocation !== undefined && slocation != "") {
      let customfield_67314Array: any[] = [];
      let slocationArray: any[] = [];
      slocationArray = slocation.split(',');
      if (slocationArray != null && slocationArray.length > 0) {
        var value1Exists = slocationArray.filter(x => x == '1')
        var value2Exists = slocationArray.filter(x => x == '2')
        var value3Exists = slocationArray.filter(x => x == '3')
        var value4Exists = slocationArray.filter(x => x == '4')
        if (value1Exists != null && value1Exists.length > 0) {
          let customfield_67314 = { id: "102649" };
          customfield_67314Array.push(customfield_67314);
        };
        if (value2Exists != null && value2Exists.length > 0) {
          let customfield_67314 = { id: "102650" };
          customfield_67314Array.push(customfield_67314);
        };
        if (value3Exists != null && value3Exists.length > 0) {
          let customfield_67314 = { id: "102651" };
          customfield_67314Array.push(customfield_67314);
        };
        if (value4Exists != null && value4Exists.length > 0) {
          let customfield_67314 = { id: "102652" };
          customfield_67314Array.push(customfield_67314);
        };
        fields.customfield_67314 = customfield_67314Array;
      }
    }

    let internalusers = localStorage.getItem("internalusers");
    let iusers = '';
    if (internalusers !== null && internalusers !== undefined && internalusers != "") {
      if (internalusers == "1") {
        iusers = "0 to 50"
      } else if (internalusers == "2") {
        iusers = "51 to 200"
      } else if (internalusers == "3") {
        iusers = "201 to 1,000"
      } else if (internalusers == "4") {
        iusers = "1,001 to 2,000"
      } else if (internalusers == "5") {
        iusers = "More than 2,000"
      }
      let customfield_67312 = { value: iusers }
      fields.customfield_67312 = customfield_67312;
    }


    let externalusers = localStorage.getItem("externalusers");
    let eusers = '';
    if (externalusers !== null && externalusers !== undefined && externalusers != "") {
      if (externalusers == "1") {
        eusers = "0 to 50"
      } else if (externalusers == "2") {
        eusers = "51 to 200"
      } else if (externalusers == "3") {
        eusers = "201 to 1,000"
      } else if (externalusers == "4") {
        eusers = "1,001 to 2,000"
      } else if (externalusers == "5") {
        eusers = "More than 2,000"
      }
      let customfield_67313 = { value: eusers }
      fields.customfield_67313 = customfield_67313;
    }


    let pdelivery = localStorage.getItem("pdelivery");
    let productDelivery = '';
    if (pdelivery !== null && pdelivery !== undefined && pdelivery != "") {
      if (pdelivery == "1") {
        productDelivery = "102635"
      } else if (pdelivery == "2") {
        productDelivery = "102636"
      } else if (pdelivery == "3") {
        productDelivery = "102637"
      } else if (pdelivery == "4") {
        productDelivery = "102638"
      } else if (pdelivery == "5") {
        productDelivery = "103502"
      }
      let customfield_67311 = { id: productDelivery }
      fields.customfield_67311 = customfield_67311;
    }

    let bprocess = localStorage.getItem("bprocess");
    let businessProcess = '';
    if (bprocess !== null && bprocess !== undefined && bprocess != "") {
      if (bprocess == "1") {
        businessProcess = "102631"
      } else if (bprocess == "2") {
        businessProcess = "102632"
      } else if (bprocess == "3") {
        businessProcess = "102633"
      } else if (bprocess == "4") {
        businessProcess = "102634"
      } else if (bprocess == "5") {
        businessProcess = "103501"
      }
      let customfield_67310 = { id: businessProcess }
      fields.customfield_67310 = customfield_67310;
    }


    let rpobjective = localStorage.getItem("rpobjective");
    let recoveryobjective = '';
    if (rpobjective !== null && rpobjective !== undefined && rpobjective != "") {
      if (rpobjective == "1") {
        recoveryobjective = "102625"
      } else if (rpobjective == "2") {
        recoveryobjective = "102626"
      } else if (rpobjective == "3") {
        recoveryobjective = "102627"
      } else if (rpobjective == "4") {
        recoveryobjective = "102628"
      } else if (rpobjective == "5") {
        recoveryobjective = "102629"
      } else if (rpobjective == "6") {
        recoveryobjective = "102630"
      }
      let customfield_67309 = { id: recoveryobjective }
      fields.customfield_67309 = customfield_67309;
    }


    let rtobjective = localStorage.getItem("rtobjective");
    let recoveryTimeobjective = '';
    if (rtobjective !== null && rtobjective !== undefined && rtobjective != "") {
      if (rtobjective == "1") {
        recoveryTimeobjective = "102621"
      } else if (rtobjective == "2") {
        recoveryTimeobjective = "102622"
      } else if (rtobjective == "3") {
        recoveryTimeobjective = "102804"
      } else if (rtobjective == "4") {
        recoveryTimeobjective = "102623"
      } else if (rtobjective == "5") {
        recoveryTimeobjective = "102624"
      }
      let customfield_67308 = { id: recoveryTimeobjective }
      fields.customfield_67308 = customfield_67308;
    }

    let llmuse = localStorage.getItem("llmuse");
    let llmUsage = '';
    if (llmuse !== null && llmuse !== undefined && llmuse != "") {
      if (llmuse == "1") {
        llmUsage = "Yes"
      } else if (llmuse == "2") {
        llmUsage = "No"
      }
      let customfield_76800 = { value: llmUsage }
      fields.customfield_76800 = customfield_76800;
    }

    let tusage = localStorage.getItem("tusage");
    let techUsage = '';
    if (tusage !== null && tusage !== undefined && tusage != "") {
      if (tusage == "1") {
        techUsage = "Yes"
      } else if (tusage == "2") {
        techUsage = "No"
      }
      let customfield_67307 = { value: techUsage }
      fields.customfield_67307 = customfield_67307;
    }


    let dtypes = localStorage.getItem("dtypes");
    let dataTypesArray: any[] = [];
    let customfield_57601Arrays: any[] = [];
    if (dtypes !== null && dtypes !== undefined && dtypes != "") {

      dataTypesArray = dtypes.split(',');
      console.log('dataTypesArray', dataTypesArray);
      if (dataTypesArray != null && dataTypesArray.length > 0) {
        let value1Exists = dataTypesArray.filter(x => x == '1');
        let value2Exists = dataTypesArray.filter(x => x == '2');
        let value3Exists = dataTypesArray.filter(x => x == '3');
        let value4Exists = dataTypesArray.filter(x => x == '4');
        let value5Exists = dataTypesArray.filter(x => x == '5');
        let value6Exists = dataTypesArray.filter(x => x == '6');
        let value7Exists = dataTypesArray.filter(x => x == '7');
        let value8Exists = dataTypesArray.filter(x => x == '8');
        let value9Exists = dataTypesArray.filter(x => x == '9');
        let value10Exists = dataTypesArray.filter(x => x == '10');
        let value11Exists = dataTypesArray.filter(x => x == '11');
        let value12Exists = dataTypesArray.filter(x => x == '12');
        let value13Exists = dataTypesArray.filter(x => x == '13');
        let value14Exists = dataTypesArray.filter(x => x == '14');

        if (value1Exists && value1Exists.length > 0) {
          let dataTypes = { id: "86005" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value2Exists && value2Exists.length > 0) {
          let dataTypes = { id: "86006" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value3Exists && value3Exists.length > 0) {
          let dataTypes = { id: "86007" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value4Exists && value4Exists.length > 0) {
          let dataTypes = { id: "86008" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value5Exists && value5Exists.length > 0) {
          let dataTypes = { id: "86009" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value6Exists && value6Exists.length > 0) {
          let dataTypes = { id: "86010" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value7Exists && value7Exists.length > 0) {
          let dataTypes = { id: "86011" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value8Exists && value8Exists.length > 0) {
          let dataTypes = { id: "86012" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value9Exists && value9Exists.length > 0) {
          let dataTypes = { id: "86013" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value10Exists && value10Exists.length > 0) {
          let dataTypes = { id: "86014" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value11Exists && value11Exists.length > 0) {
          let dataTypes = { id: "86015" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value12Exists && value12Exists.length > 0) {
          let dataTypes = { id: "86016" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value13Exists && value13Exists.length > 0) {
          let dataTypes = { id: "86017" }
          customfield_57601Arrays.push(dataTypes);
        }
        if (value14Exists && value14Exists.length > 0) {
          let dataTypes = { id: "120309" }
          customfield_57601Arrays.push(dataTypes);
        }
        console.log('customfield_57601Arrays', customfield_57601Arrays);
        // let customfield_57601 = customfield_57601Arrays;
        fields.customfield_57601 = customfield_57601Arrays;
      }
    }

    let intpropdtypes = localStorage.getItem("intpropdtypes");
    let intpropdataTypesArray: any[] = [];
    let customfield_76701Arrays: any[] = [];
    if (intpropdtypes !== null && intpropdtypes !== undefined && intpropdtypes != "") {

      intpropdataTypesArray = intpropdtypes.split(',');
      console.log('intpropdataTypesArray', intpropdataTypesArray);
      if (intpropdataTypesArray != null && intpropdataTypesArray.length > 0) {
        let value1Exists = intpropdataTypesArray.filter(x => x == '1');
        let value2Exists = intpropdataTypesArray.filter(x => x == '2');
        let value3Exists = intpropdataTypesArray.filter(x => x == '3');
        let value4Exists = intpropdataTypesArray.filter(x => x == '4');
        let value5Exists = intpropdataTypesArray.filter(x => x == '5');
        let value6Exists = intpropdataTypesArray.filter(x => x == '6');
        let value7Exists = intpropdataTypesArray.filter(x => x == '7');
        let value8Exists = intpropdataTypesArray.filter(x => x == '8');
        let value9Exists = intpropdataTypesArray.filter(x => x == '9');
        let value10Exists = intpropdataTypesArray.filter(x => x == '10');

        if (value1Exists && value1Exists.length > 0) {
          let intpropdatatypes = { id: "120310" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value2Exists && value2Exists.length > 0) {
          let intpropdatatypes = { id: "120311" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value3Exists && value3Exists.length > 0) {
          let intpropdatatypes = { id: "120312" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value4Exists && value4Exists.length > 0) {
          let intpropdatatypes = { id: "120313" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value5Exists && value5Exists.length > 0) {
          let intpropdatatypes = { id: "120314" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value6Exists && value6Exists.length > 0) {
          let intpropdatatypes = { id: "120315" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value7Exists && value7Exists.length > 0) {
          let intpropdatatypes = { id: "120316" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value8Exists && value8Exists.length > 0) {
          let intpropdatatypes = { id: "120317" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value9Exists && value9Exists.length > 0) {
          let intpropdatatypes = { id: "120318" }
          customfield_76701Arrays.push(intpropdatatypes);
        }
        if (value10Exists && value10Exists.length > 0) {
          let intpropdatatypes = { id: "120319" }
          customfield_76701Arrays.push(intpropdatatypes);
        }

        console.log('customfield_76701Arrays', customfield_76701Arrays);
        let customfield_76701 = customfield_76701Arrays
        fields.customfield_76701 = customfield_76701;
      }
    }

    let pdatacollection = localStorage.getItem("pdatacollection");
    let personalDataCollection: any;
    let customfield_57602Array: any[] = [];
    let pdatacollectionArray: any[] = [];
    if (pdatacollection !== null && pdatacollection !== undefined && pdatacollection != "") {
      pdatacollectionArray = pdatacollection.split(',');
      if (pdatacollectionArray != null && pdatacollectionArray.length > 0) {
        if (pdatacollectionArray.filter(x => x == '1').length > 0) {
          let personalDataCollection = { id: "86018" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '2').length > 0) {
          personalDataCollection = { id: "86019" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '3').length > 0) {
          personalDataCollection = { id: "86020" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '4').length > 0) {
          personalDataCollection = { id: "86021" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '5').length > 0) {
          personalDataCollection = { id: "86022" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '6').length > 0) {
          personalDataCollection = { id: "86023" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '7').length > 0) {
          personalDataCollection = { id: "86024" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '8').length > 0) {
          personalDataCollection = { id: "86025" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '9').length > 0) {
          personalDataCollection = { id: "86026" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '10').length > 0) {
          personalDataCollection = { id: "86027" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '11').length > 0) {
          personalDataCollection = { id: "86028" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '12').length > 0) {
          personalDataCollection = { id: "86029" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '13').length > 0) {
          personalDataCollection = { id: "86030" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '14').length > 0) {
          personalDataCollection = { id: "86031" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '15').length > 0) {
          personalDataCollection = { id: "86032" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '16').length > 0) {
          personalDataCollection = { id: "86033" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '17').length > 0) {
          personalDataCollection = { id: "86034" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '18').length > 0) {
          personalDataCollection = { id: "86035" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '19').length > 0) {
          personalDataCollection = { id: "86036" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '20').length > 0) {
          personalDataCollection = { id: "86037" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '21').length > 0) {
          personalDataCollection = { id: "86038" }
          customfield_57602Array.push(personalDataCollection);
        }
        if (pdatacollectionArray.filter(x => x == '22').length > 0) {
          personalDataCollection = { id: "102600" }
          customfield_57602Array.push(personalDataCollection);
        }
        let customfield_57602 = customfield_57602Array
        fields.customfield_57602 = customfield_57602;
      }
    }


    let dlocation = localStorage.getItem("dlocation");
    let dataLocation = '';
    if (dlocation !== null && dlocation !== undefined && dlocation != "") {
      if (dlocation == "1") {
        dataLocation = "Yes"
      } else if (dlocation == "2") {
        dataLocation = "No"
      }
      let customfield_67306 = { value: dataLocation }
      fields.customfield_67306 = customfield_67306;
    }

    let dsharing = localStorage.getItem("dsharing");
    let dataSharing = '';
    if (dsharing !== null && dsharing !== undefined && dsharing != "") {
      if (dsharing == "1") {
        dataSharing = "Yes"
      } else if (dsharing == "2") {
        dataSharing = "No"
      }
      let customfield_67305 = { value: dataSharing }
      fields.customfield_67305 = customfield_67305;
    }

    let oadevelopment = localStorage.getItem("oadevelopment");
    let outsorcedAppDevelopment = '';
    if (oadevelopment !== null && oadevelopment !== undefined && oadevelopment != "") {
      if (oadevelopment == "1") {
        outsorcedAppDevelopment = "Yes"
      } else if (oadevelopment == "2") {
        outsorcedAppDevelopment = "No"
      }
      let customfield_67304 = { value: outsorcedAppDevelopment }
      fields.customfield_67304 = customfield_67304;
    }

    let otherIntpropdaccess = localStorage.getItem("otherIntpropdaccess");
    if (otherIntpropdaccess !== null && otherIntpropdaccess !== undefined && otherIntpropdaccess != "") {
      let customfield_76702 = otherIntpropdaccess
      fields.customfield_76702 = customfield_76702;
    }

    let dvolume = localStorage.getItem("dvolume");
    let dataVolume = '';
    if (dvolume !== null && dvolume !== undefined && dvolume != "") {
      if (dvolume == "1") {
        dataVolume = "86041"
      } else if (dvolume == "2") {
        dataVolume = "86042"
      } else if (dvolume == "3") {
        dataVolume = "86043"
      } else if (dvolume == "4") {
        dataVolume = "86044"
      } else if (dvolume == "5") {
        dataVolume = "86045"
      } else if (dvolume == "6") {
        dataVolume = "102655"
      }
      let customfield_57604 = { id: dataVolume }
      fields.customfield_57604 = customfield_57604;
    }

    let susage = localStorage.getItem("susage");
    let softwareUsage = '';
    if (susage !== null && susage !== undefined && susage != "") {
      if (susage == "1") {
        softwareUsage = "Yes"
      } else if (susage == "2") {
        softwareUsage = "No"
      }
      let customfield_67303 = { value: softwareUsage }
      fields.customfield_67303 = customfield_67303;
    }


    let smaintenance = localStorage.getItem("smaintenance");
    let softwareMaintenance = '';
    if (smaintenance !== null && smaintenance !== undefined && smaintenance != "") {
      if (smaintenance == "1") {
        softwareMaintenance = "Yes"
      } else if (smaintenance == "2") {
        softwareMaintenance = "No"
      } else if (smaintenance == "3") {
        softwareMaintenance = "Not Applicable"
      }
      let customfield_67401 = { value: softwareMaintenance }
      fields.customfield_67401 = customfield_67401;
    }


    let saccess = localStorage.getItem("saccess");
    let softwareAccess: any;
    let softwareAccessArrray: any[] = [];
    let customfield_57608Array: any[] = [];
    if (saccess !== null && saccess !== undefined && saccess != "") {
      softwareAccessArrray = saccess.split(',');
      if (softwareAccessArrray != null && softwareAccessArrray.length > 0) {
        if (softwareAccessArrray.filter(x => x == '1').length > 0) {
          softwareAccess = { id: "86064" }
          customfield_57608Array.push(softwareAccess);
        }
        if (softwareAccessArrray.filter(x => x == '2').length > 0) {
          softwareAccess = { id: "86065" }
          customfield_57608Array.push(softwareAccess);
        }
        if (softwareAccessArrray.filter(x => x == '3').length > 0) {
          softwareAccess = { id: "86066" }
          customfield_57608Array.push(softwareAccess);
        }
        if (softwareAccessArrray.filter(x => x == '4').length > 0) {
          softwareAccess = { id: "86067" }
          customfield_57608Array.push(softwareAccess);
        }
        if (softwareAccessArrray.filter(x => x == '5').length > 0) {
          softwareAccess = { id: "86068" }
          customfield_57608Array.push(softwareAccess);
        }
        let customfield_57608 = customfield_57608Array
        fields.customfield_57608 = customfield_57608;
      }
    }

    let shosting = localStorage.getItem("shosting");
    let softwareHosting = '';
    if (shosting !== null && shosting !== undefined && shosting != "") {
      if (shosting == "1") {
        softwareHosting = "102605"
      } else if (shosting == "2") {
        softwareHosting = "102606"
      } else if (shosting == "3") {
        softwareHosting = "102607"
      } else if (shosting == "4") {
        softwareHosting = "102608"
      }
      let customfield_67302 = { id: softwareHosting }
      fields.customfield_67302 = customfield_67302;
    }

    let sauthentication = localStorage.getItem("sauthentication");
    let softwareAuthentication = '';
    if (sauthentication !== null && sauthentication !== undefined && sauthentication != "") {
      if (sauthentication == "1") {
        softwareAuthentication = "Yes"
      } else if (sauthentication == "2") {
        softwareAuthentication = "No"
      } else if (sauthentication == "3") {
        softwareAuthentication = "N/A"
      }
      let customfield_68100 = { value: softwareAuthentication }
      fields.customfield_68100 = customfield_68100;
    }

    let dtransmission = localStorage.getItem("dtransmission");
    let dataTranmission: any;
    let customfield_57605Array: any[] = [];
    let dtransmissionArray: any[] = [];
    if (dtransmission !== null && dtransmission !== undefined && dtransmission != "") {
      dtransmissionArray = dtransmission.split(',');
      if (dtransmissionArray != null && dtransmissionArray.length > 0) {
        if (dtransmissionArray.filter(x => x == '1').length > 0) {
          dataTranmission = { id: "86046" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '2').length > 0) {
          dataTranmission = { id: "86047" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '3').length > 0) {
          dataTranmission = { id: "86048" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '4').length > 0) {
          dataTranmission = { id: "86049" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '5').length > 0) {
          dataTranmission = { id: "103500" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '6').length > 0) {
          dataTranmission = { id: "86050" }
          customfield_57605Array.push(dataTranmission);
        }
        if (dtransmissionArray.filter(x => x == '7').length > 0) {
          dataTranmission = { id: "86051" }
          customfield_57605Array.push(dataTranmission);
        }
        let customfield_57605 = customfield_57605Array
        fields.customfield_57605 = customfield_57605;
      }
    }


    let sintegration = localStorage.getItem("sintegration");
    let systemIntegration: any;
    let customfield_57606Array: any[] = [];
    let sintegrationArray: any[] = [];
    if (sintegration !== null && sintegration !== undefined && sintegration != "") {
      sintegrationArray = sintegration.split(',');
      if (sintegrationArray != null && sintegrationArray.length > 0) {
        if (sintegrationArray.filter(x => x == '1').length > 0) {
          systemIntegration = { id: "86052" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '2').length > 0) {
          systemIntegration = { id: "86053" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '3').length > 0) {
          systemIntegration = { id: "86054" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '4').length > 0) {
          systemIntegration = { id: "86055" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '5').length > 0) {
          systemIntegration = { id: "86056" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '6').length > 0) {
          systemIntegration = { id: "102611" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '7').length > 0) {
          systemIntegration = { id: "86057" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '8').length > 0) {
          systemIntegration = "86058"
          systemIntegration = { id: "86058" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '9').length > 0) {
          systemIntegration = { id: "86059" }
          customfield_57606Array.push(systemIntegration);
        }
        if (sintegrationArray.filter(x => x == '10').length > 0) {
          systemIntegration = { id: "86060" }
          customfield_57606Array.push(systemIntegration);
        }
        let customfield_57606 = customfield_57606Array
        fields.customfield_57606 = customfield_57606;
      }
    }


    let ccustomers = localStorage.getItem("ccustomers");
    let contactCustomers = '';
    if (ccustomers !== null && ccustomers !== undefined && ccustomers != "") {
      if (ccustomers == "1") {
        contactCustomers = "Yes"
      } else if (ccustomers == "2") {
        contactCustomers = "No"
      }
      let customfield_67300 = { value: contactCustomers }
      fields.customfield_67300 = customfield_67300;
    }


    let subcontractors = localStorage.getItem("subcontractors");
    let contractors = '';
    if (subcontractors !== null && subcontractors !== undefined && subcontractors != "") {
      if (subcontractors == "1") {
        contractors = "Yes"
      } else if (subcontractors == "2") {
        contractors = "No"
      }
      let customfield_67301 = { value: contractors }
      fields.customfield_67301 = customfield_67301;
    }

    let dataset = localStorage.getItem("dataset");
    let dataElement = '';
    if (dataset !== null && dataset !== undefined && dataset != "") {
      if (dataset == "1") {
        dataElement = "Yes"
      } else if (dataset == "2") {
        dataElement = "No"
      }
      let customfield_67500 = { value: dataElement }
      fields.customfield_67500 = customfield_67500;
    }


    if (this.virascore !== null && this.virascore !== undefined && this.virascore != "") {
      let customfield_66500 = this.virascore
      fields.customfield_66500 = customfield_66500;
    }

    if (this.level !== null && this.level !== undefined && this.level != "") {
      let customfield_43202 = { value: this.level }
      fields.customfield_43202 = customfield_43202;
    }

    let describePS = localStorage.getItem("describePS");
    if (describePS !== null && describePS !== 'null' && describePS !== undefined && describePS != "") {
      fields.customfield_67800 = describePS;
    }

    let describePD = localStorage.getItem("describePD");
    if (describePD !== null && describePD !== 'null' && describePD !== undefined && describePD != "") {
      fields.customfield_67801 = describePD;
    }

    let describePT = localStorage.getItem("describePT");
    if (describePT !== null && describePT !== 'null' && describePT !== undefined && describePT != "") {
      fields.customfield_67802 = describePT;
    }

    let describeSI = localStorage.getItem("describeSI");
    if (describeSI !== null && describeSI !== 'null' && describeSI !== undefined && describeSI != "") {
      fields.customfield_67803 = describeSI;
    }

    let describeCE = localStorage.getItem("describeCE");
    if (describeCE !== null && describeCE !== 'null' && describeCE !== undefined && describeCE != "") {
      fields.customfield_67900 = describeCE;
    }

    let othersoftware = localStorage.getItem("othersoftware");
    if (othersoftware !== null && othersoftware !== 'null' && othersoftware !== undefined && othersoftware != "") {
      fields.customfield_68300 = othersoftware;
    }

    return fields;
  }


formPayload_SoftwareReview() {

  let fields: any = {};

  let project = { id: "12501" };
  fields.project = project;

  let issuetype = { id: "10203" };
  fields.issuetype = issuetype;

 /* let assignee = { name: "Security Compliance Team" };
  fields.assignee = assignee; */

  let reporter = { name: this.user };
  fields.reporter = reporter;

  let priority = { id: "4" };
  fields.priority = priority;

  let components = [{ id: "23206" }];
  fields.components = components;

  let labels = ["application_review"];
  fields.labels = labels;

  let security = { id: "11300" };
  fields.security = security;

  let vendorName = localStorage.getItem("vname");
  if (vendorName !== null && vendorName !== undefined && vendorName != "") {
    let customfield_29510 = vendorName;
    fields.customfield_29510 = customfield_29510;
  }

  let summary = "Software Review Request for " + vendorName;
  fields.summary = summary;
  fields.description = summary;


  let evendor = localStorage.getItem("evendor");
  let vendorType = '';
  if (evendor !== null && evendor !== undefined && evendor != "") {
    if (evendor == "1") {
      vendorType = "Existing Vendor"
    } else if (evendor == "2") {
      vendorType = "New Vendor"
    }
    let customfield_56500 = { value: vendorType }
    fields.customfield_56500 = customfield_56500;
  }

  let scope = localStorage.getItem("scope");
  let scopeChanged = "";
  if (scope !== null && scope !== 'null' && scope !== undefined && scope != "") {
    if (scope == "1") {
      scopeChanged = "Yes"
    } else if (scope == "2") {
      scopeChanged = "No"
    }
    let customfield_58100 = { value: scopeChanged }
    fields.customfield_58100 = customfield_58100;
  }

  let scopeEngagement = localStorage.getItem("engagement");
  if (scopeEngagement !== null && scopeEngagement !== undefined && scopeEngagement != "") {
    let customfield_27400 = scopeEngagement;
    fields.customfield_27400 = customfield_27400;
  }

 /* let sreview = localStorage.getItem("sreview");
  let softwareReview = "";
  if (sreview !== null && sreview !== undefined && sreview != "") {
    if (sreview == "1") {
      softwareReview = "Yes"
    } else if (sreview == "2") {
      softwareReview = "No"
    }
    let customfield_58101 = { value: softwareReview }
    fields.customfield_58101 = customfield_58101;
  } */

  let cwebsite = localStorage.getItem("cwebsite");
  if (cwebsite !== null && cwebsite !== 'null' && cwebsite !== undefined && cwebsite != "") {
    let customfield_32738 = '';
    if (cwebsite.toLocaleLowerCase().startsWith('https://')) {
       customfield_32738 = cwebsite;
    } else {
      customfield_32738 = 'https://' + cwebsite;
    }
    fields.customfield_32738 = customfield_32738;
  }

  let sname = localStorage.getItem("sname");
  if (sname !== null && sname !== 'null' && sname !== undefined && sname != "") {
    let customfield_68200 ;
    let sedition = localStorage.getItem("softwareedition");
    if (sedition !== null && sedition !== undefined && sedition !== 'null' && sedition !== "") {
       customfield_68200 = { value: sname , child : {value: sedition} }
    }  else {
       customfield_68200 = { value: sname }
    }
    fields.customfield_68200 = customfield_68200;
  }



  /*  if (this.multiVendorLocation !== null && this.multiVendorLocation !== undefined && this.multiVendorLocation !== "") {
      let customfield_57603 =  {value: this.multiVendorLocation}
      fields.customfield_57603 = customfield_57603;
    } */



  let vlocation = localStorage.getItem("vlocation");
  if (vlocation !== null && vlocation !== undefined && vlocation != "") {
    let customfield_22803Array: any[] = [];
    let vlocationArray: any[] = [];
    vlocationArray = vlocation.split(',');
    if (vlocationArray != null && vlocationArray.length > 0) {
      var value1Exists = vlocationArray.filter(x => x == '1')
      var value2Exists = vlocationArray.filter(x => x == '2')
      if (value1Exists != null && value1Exists.length > 0) {
        let customfield_22803 = { value: "United States" };
        customfield_22803Array.push(customfield_22803);
      };
      if (value2Exists != null && value2Exists.length > 0) {
        let customfield_22803 = { value: "Out of USA" };
        customfield_22803Array.push(customfield_22803);
        let customfield_57603 = { value: "Yes" }
        fields.customfield_57603 = customfield_57603;
      };
      fields.customfield_22803 = customfield_22803Array;
    }
  }


  let ulocation = localStorage.getItem("ulocation");
  if (ulocation !== null && ulocation !== undefined && ulocation != "") {
    let customfield_67315Array: any[] = [];
    let ulocationArray: any[] = [];
    ulocationArray = ulocation.split(',');
    if (ulocationArray != null && ulocationArray.length > 0) {
      var value1Exists = ulocationArray.filter(x => x == '1')
      var value2Exists = ulocationArray.filter(x => x == '2')
      if (value1Exists != null && value1Exists.length > 0) {
        let customfield_67315 = { value: "USA" };
        customfield_67315Array.push(customfield_67315);
      };
      if (value2Exists != null && value2Exists.length > 0) {
        let customfield_67315 = { value: "Out of USA" };
        customfield_67315Array.push(customfield_67315);
      };
      fields.customfield_67315 = customfield_67315Array;
    }
  }


  let slocation = localStorage.getItem("slocation");
  if (slocation !== null && slocation !== undefined && slocation != "") {
    let customfield_67314Array: any[] = [];
    let slocationArray: any[] = [];
    slocationArray = slocation.split(',');
    if (slocationArray != null && slocationArray.length > 0) {
      var value1Exists = slocationArray.filter(x => x == '1')
      var value2Exists = slocationArray.filter(x => x == '2')
      var value3Exists = slocationArray.filter(x => x == '3')
      var value4Exists = slocationArray.filter(x => x == '4')
      if (value1Exists != null && value1Exists.length > 0) {
        let customfield_67314 = { id: "102649" };
        customfield_67314Array.push(customfield_67314);
      };
      if (value2Exists != null && value2Exists.length > 0) {
        let customfield_67314 = { id: "102650" };
        customfield_67314Array.push(customfield_67314);
      };
      if (value3Exists != null && value3Exists.length > 0) {
        let customfield_67314 = { id: "102651" };
        customfield_67314Array.push(customfield_67314);
      };
      if (value4Exists != null && value4Exists.length > 0) {
        let customfield_67314 = { id: "102652" };
        customfield_67314Array.push(customfield_67314);
      };
      fields.customfield_67314 = customfield_67314Array;
    }
  }

  let internalusers = localStorage.getItem("internalusers");
  let iusers = '';
  if (internalusers !== null && internalusers !== undefined && internalusers != "") {
    if (internalusers == "1") {
      iusers = "0 to 50"
    } else if (internalusers == "2") {
      iusers = "51 to 200"
    } else if (internalusers == "3") {
      iusers = "201 to 1,000"
    } else if (internalusers == "4") {
      iusers = "1,001 to 2,000"
    } else if (internalusers == "5") {
      iusers = "More than 2,000"
    }
    let customfield_67312 = { value: iusers }
    fields.customfield_67312 = customfield_67312;
  }


  let externalusers = localStorage.getItem("externalusers");
  let eusers = '';
  if (externalusers !== null && externalusers !== undefined && externalusers != "") {
    if (externalusers == "1") {
      eusers = "0 to 50"
    } else if (externalusers == "2") {
      eusers = "51 to 200"
    } else if (externalusers == "3") {
      eusers = "201 to 1,000"
    } else if (externalusers == "4") {
      eusers = "1,001 to 2,000"
    } else if (externalusers == "5") {
      eusers = "More than 2,000"
    }
    let customfield_67313 = { value: eusers }
    fields.customfield_67313 = customfield_67313;
  }


  let pdelivery = localStorage.getItem("pdelivery");
  let productDelivery = '';
  if (pdelivery !== null && pdelivery !== undefined && pdelivery != "") {
    if (pdelivery == "1") {
      productDelivery = "102635"
    } else if (pdelivery == "2") {
      productDelivery = "102636"
    } else if (pdelivery == "3") {
      productDelivery = "102637"
    } else if (pdelivery == "4") {
      productDelivery = "102638"
    } else if (pdelivery == "5") {
      productDelivery = "103502"
    }
    let customfield_67311 = { id: productDelivery }
    fields.customfield_67311 = customfield_67311;
  }

  let bprocess = localStorage.getItem("bprocess");
  let businessProcess = '';
  if (bprocess !== null && bprocess !== undefined && bprocess != "") {
    if (bprocess == "1") {
      businessProcess = "102631"
    } else if (bprocess == "2") {
      businessProcess = "102632"
    } else if (bprocess == "3") {
      businessProcess = "102633"
    } else if (bprocess == "4") {
      businessProcess = "102634"
    } else if (bprocess == "5") {
      businessProcess = "103501"
    }
    let customfield_67310 = { id: businessProcess }
    fields.customfield_67310 = customfield_67310;
  }


  let rpobjective = localStorage.getItem("rpobjective");
  let recoveryobjective = '';
  if (rpobjective !== null && rpobjective !== undefined && rpobjective != "") {
    if (rpobjective == "1") {
      recoveryobjective = "102625"
    } else if (rpobjective == "2") {
      recoveryobjective = "102626"
    } else if (rpobjective == "3") {
      recoveryobjective = "102627"
    } else if (rpobjective == "4") {
      recoveryobjective = "102628"
    } else if (rpobjective == "5") {
      recoveryobjective = "102629"
    } else if (rpobjective == "6") {
      recoveryobjective = "102630"
    }
    let customfield_67309 = { id: recoveryobjective }
    fields.customfield_67309 = customfield_67309;
  }


  let rtobjective = localStorage.getItem("rtobjective");
  let recoveryTimeobjective = '';
  if (rtobjective !== null && rtobjective !== undefined && rtobjective != "") {
    if (rtobjective == "1") {
      recoveryTimeobjective = "102621"
    } else if (rtobjective == "2") {
      recoveryTimeobjective = "102622"
    } else if (rtobjective == "3") {
      recoveryTimeobjective = "102804"
    } else if (rtobjective == "4") {
      recoveryTimeobjective = "102623"
    } else if (rtobjective == "5") {
      recoveryTimeobjective = "102624"
    }
    let customfield_67308 = { id: recoveryTimeobjective }
    fields.customfield_67308 = customfield_67308;
  }

  let llmuse = localStorage.getItem("llmuse");
  let llmUsage = '';
  if (llmuse !== null && llmuse !== undefined && llmuse != "") {
    if (llmuse == "1") {
      llmUsage = "Yes"
    } else if (llmuse == "2") {
      llmUsage = "No"
    }
    let customfield_76800 = { value: llmUsage }
    fields.customfield_76800 = customfield_76800;
  }

  let tusage = localStorage.getItem("tusage");
  let techUsage = '';
  if (tusage !== null && tusage !== undefined && tusage != "") {
    if (tusage == "1") {
      techUsage = "Yes"
    } else if (tusage == "2") {
      techUsage = "No"
    }
    let customfield_67307 = { value: techUsage }
    fields.customfield_67307 = customfield_67307;
  }


  let dtypes = localStorage.getItem("dtypes");
  let dataTypesArray: any[] = [];
  let customfield_57601Arrays: any[] = [];
  if (dtypes !== null && dtypes !== undefined && dtypes != "") {

    dataTypesArray = dtypes.split(',');
    console.log('dataTypesArray', dataTypesArray);
    if (dataTypesArray != null && dataTypesArray.length > 0) {
      let value1Exists = dataTypesArray.filter(x => x == '1');
      let value2Exists = dataTypesArray.filter(x => x == '2');
      let value3Exists = dataTypesArray.filter(x => x == '3');
      let value4Exists = dataTypesArray.filter(x => x == '4');
      let value5Exists = dataTypesArray.filter(x => x == '5');
      let value6Exists = dataTypesArray.filter(x => x == '6');
      let value7Exists = dataTypesArray.filter(x => x == '7');
      let value8Exists = dataTypesArray.filter(x => x == '8');
      let value9Exists = dataTypesArray.filter(x => x == '9');
      let value10Exists = dataTypesArray.filter(x => x == '10');
      let value11Exists = dataTypesArray.filter(x => x == '11');
      let value12Exists = dataTypesArray.filter(x => x == '12');
      let value13Exists = dataTypesArray.filter(x => x == '13');

      if (value1Exists && value1Exists.length > 0) {
        let dataTypes = { id: "86005" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value2Exists && value2Exists.length > 0) {
        let dataTypes = { id: "86006" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value3Exists && value3Exists.length > 0) {
        let dataTypes = { id: "86007" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value4Exists && value4Exists.length > 0) {
        let dataTypes = { id: "86008" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value5Exists && value5Exists.length > 0) {
        let dataTypes = { id: "86009" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value6Exists && value6Exists.length > 0) {
        let dataTypes = { id: "86010" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value7Exists && value7Exists.length > 0) {
        let dataTypes = { id: "86011" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value8Exists && value8Exists.length > 0) {
        let dataTypes = { id: "86012" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value9Exists && value9Exists.length > 0) {
        let dataTypes = { id: "86013" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value10Exists && value10Exists.length > 0) {
        let dataTypes = { id: "86014" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value11Exists && value11Exists.length > 0) {
        let dataTypes = { id: "86015" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value12Exists && value12Exists.length > 0) {
        let dataTypes = { id: "86016" }
        customfield_57601Arrays.push(dataTypes);
      }
      if (value13Exists && value13Exists.length > 0) {
        let dataTypes = { id: "86017" }
        customfield_57601Arrays.push(dataTypes);
      }
      console.log('customfield_57601Arrays', customfield_57601Arrays);
      // let customfield_57601 = customfield_57601Arrays;
      fields.customfield_57601 = customfield_57601Arrays;
    }
  }

  let intpropdtypes = localStorage.getItem("intpropdtypes");
  let intpropdataTypesArray: any[] = [];
  let customfield_76701Arrays: any[] = [];
  if (intpropdtypes !== null && intpropdtypes !== undefined && intpropdtypes != "") {

    intpropdataTypesArray = intpropdtypes.split(',');
    console.log('intpropdataTypesArray', intpropdataTypesArray);
    if (intpropdataTypesArray != null && intpropdataTypesArray.length > 0) {
      let value1Exists = intpropdataTypesArray.filter(x => x == '1');
      let value2Exists = intpropdataTypesArray.filter(x => x == '2');
      let value3Exists = intpropdataTypesArray.filter(x => x == '3');
      let value4Exists = intpropdataTypesArray.filter(x => x == '4');
      let value5Exists = intpropdataTypesArray.filter(x => x == '5');
      let value6Exists = intpropdataTypesArray.filter(x => x == '6');
      let value7Exists = intpropdataTypesArray.filter(x => x == '7');
      let value8Exists = intpropdataTypesArray.filter(x => x == '8');
      let value9Exists = intpropdataTypesArray.filter(x => x == '9');
      let value10Exists = intpropdataTypesArray.filter(x => x == '10');

      if (value1Exists && value1Exists.length > 0) {
        let intpropdatatypes = { id: "120310" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value2Exists && value2Exists.length > 0) {
        let intpropdatatypes = { id: "120311" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value3Exists && value3Exists.length > 0) {
        let intpropdatatypes = { id: "120312" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value4Exists && value4Exists.length > 0) {
        let intpropdatatypes = { id: "120313" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value5Exists && value5Exists.length > 0) {
        let intpropdatatypes = { id: "120314" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value6Exists && value6Exists.length > 0) {
        let intpropdatatypes = { id: "120315" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value7Exists && value7Exists.length > 0) {
        let intpropdatatypes = { id: "120316" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value8Exists && value8Exists.length > 0) {
        let intpropdatatypes = { id: "120317" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value9Exists && value9Exists.length > 0) {
        let intpropdatatypes = { id: "120318" }
        customfield_76701Arrays.push(intpropdatatypes);
      }
      if (value10Exists && value10Exists.length > 0) {
        let intpropdatatypes = { id: "120319" }
        customfield_76701Arrays.push(intpropdatatypes);
      }

      console.log('customfield_76701Arrays', customfield_76701Arrays);
      let customfield_76701 = customfield_76701Arrays
      fields.customfield_76701 = customfield_76701;
    }
  }

  let pdatacollection = localStorage.getItem("pdatacollection");
  let personalDataCollection: any;
  let customfield_57602Array: any[] = [];
  let pdatacollectionArray: any[] = [];
  if (pdatacollection !== null && pdatacollection !== undefined && pdatacollection != "") {
    pdatacollectionArray = pdatacollection.split(',');
    if (pdatacollectionArray != null && pdatacollectionArray.length > 0) {
      if (pdatacollectionArray.filter(x => x == '1').length > 0) {
        let personalDataCollection = { id: "86018" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '2').length > 0) {
        personalDataCollection = { id: "86019" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '3').length > 0) {
        personalDataCollection = { id: "86020" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '4').length > 0) {
        personalDataCollection = { id: "86021" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '5').length > 0) {
        personalDataCollection = { id: "86022" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '6').length > 0) {
        personalDataCollection = { id: "86023" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '7').length > 0) {
        personalDataCollection = { id: "86024" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '8').length > 0) {
        personalDataCollection = { id: "86025" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '9').length > 0) {
        personalDataCollection = { id: "86026" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '10').length > 0) {
        personalDataCollection = { id: "86027" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '11').length > 0) {
        personalDataCollection = { id: "86028" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '12').length > 0) {
        personalDataCollection = { id: "86029" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '13').length > 0) {
        personalDataCollection = { id: "86030" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '14').length > 0) {
        personalDataCollection = { id: "86031" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '15').length > 0) {
        personalDataCollection = { id: "86032" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '16').length > 0) {
        personalDataCollection = { id: "86033" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '17').length > 0) {
        personalDataCollection = { id: "86034" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '18').length > 0) {
        personalDataCollection = { id: "86035" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '19').length > 0) {
        personalDataCollection = { id: "86036" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '20').length > 0) {
        personalDataCollection = { id: "86037" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '21').length > 0) {
        personalDataCollection = { id: "86038" }
        customfield_57602Array.push(personalDataCollection);
      }
      if (pdatacollectionArray.filter(x => x == '22').length > 0) {
        personalDataCollection = { id: "102600" }
        customfield_57602Array.push(personalDataCollection);
      }
      let customfield_57602 = customfield_57602Array
      fields.customfield_57602 = customfield_57602;
    }
  }


  let dlocation = localStorage.getItem("dlocation");
  let dataLocation = '';
  if (dlocation !== null && dlocation !== undefined && dlocation != "") {
    if (dlocation == "1") {
      dataLocation = "Yes"
    } else if (dlocation == "2") {
      dataLocation = "No"
    }
    let customfield_67306 = { value: dataLocation }
    fields.customfield_67306 = customfield_67306;
  }

  let dsharing = localStorage.getItem("dsharing");
  let dataSharing = '';
  if (dsharing !== null && dsharing !== undefined && dsharing != "") {
    if (dsharing == "1") {
      dataSharing = "Yes"
    } else if (dsharing == "2") {
      dataSharing = "No"
    }
    let customfield_67305 = { value: dataSharing }
    fields.customfield_67305 = customfield_67305;
  }

  let oadevelopment = localStorage.getItem("oadevelopment");
  let outsorcedAppDevelopment = '';
  if (oadevelopment !== null && oadevelopment !== undefined && oadevelopment != "") {
    if (oadevelopment == "1") {
      outsorcedAppDevelopment = "Yes"
    } else if (oadevelopment == "2") {
      outsorcedAppDevelopment = "No"
    }
    let customfield_67304 = { value: outsorcedAppDevelopment }
    fields.customfield_67304 = customfield_67304;
  }

  let otherIntpropdaccess = localStorage.getItem("otherIntpropdaccess");
  if (otherIntpropdaccess !== null && otherIntpropdaccess !== undefined && otherIntpropdaccess != "") {
    let customfield_76702 = otherIntpropdaccess
    fields.customfield_76702 = customfield_76702;
  }

  let dvolume = localStorage.getItem("dvolume");
  let dataVolume = '';
  if (dvolume !== null && dvolume !== undefined && dvolume != "") {
    if (dvolume == "1") {
      dataVolume = "86041"
    } else if (dvolume == "2") {
      dataVolume = "86042"
    } else if (dvolume == "3") {
      dataVolume = "86043"
    } else if (dvolume == "4") {
      dataVolume = "86044"
    } else if (dvolume == "5") {
      dataVolume = "86045"
    } else if (dvolume == "6") {
      dataVolume = "102655"
    }
    let customfield_57604 = { id: dataVolume }
    fields.customfield_57604 = customfield_57604;
  }

  let susage = localStorage.getItem("susage");
  let softwareUsage = '';
  if (susage !== null && susage !== undefined && susage != "") {
    if (susage == "1") {
      softwareUsage = "Yes"
    } else if (susage == "2") {
      softwareUsage = "No"
    }
    let customfield_67303 = { value: softwareUsage }
    fields.customfield_67303 = customfield_67303;
  }

  let smaintenance = localStorage.getItem("smaintenance");
  let softwareMaintenance = '';
  if (smaintenance !== null && smaintenance !== undefined && smaintenance != "") {
    if (smaintenance == "1") {
      softwareMaintenance = "Yes"
    } else if (smaintenance == "2") {
      softwareMaintenance = "No"
    } else if (smaintenance == "3") {
      softwareMaintenance = "Not Applicable"
    }
    let customfield_67401 = { value: softwareMaintenance }
    fields.customfield_67401 = customfield_67401;
  }


  let saccess = localStorage.getItem("saccess");
  let softwareAccess: any;
  let softwareAccessArrray: any[] = [];
  let customfield_57608Array: any[] = [];
  if (saccess !== null && saccess !== undefined && saccess != "") {
    softwareAccessArrray = saccess.split(',');
    if (softwareAccessArrray != null && softwareAccessArrray.length > 0) {
      if (softwareAccessArrray.filter(x => x == '1').length > 0) {
        softwareAccess = { id: "86064" }
        customfield_57608Array.push(softwareAccess);
      }
      if (softwareAccessArrray.filter(x => x == '2').length > 0) {
        softwareAccess = { id: "86065" }
        customfield_57608Array.push(softwareAccess);
      }
      if (softwareAccessArrray.filter(x => x == '3').length > 0) {
        softwareAccess = { id: "86066" }
        customfield_57608Array.push(softwareAccess);
      }
      if (softwareAccessArrray.filter(x => x == '4').length > 0) {
        softwareAccess = { id: "86067" }
        customfield_57608Array.push(softwareAccess);
      }
      if (softwareAccessArrray.filter(x => x == '5').length > 0) {
        softwareAccess = { id: "86068" }
        customfield_57608Array.push(softwareAccess);
      }
      let customfield_57608 = customfield_57608Array
      fields.customfield_57608 = customfield_57608;
    }
  }


  let sauthentication = localStorage.getItem("sauthentication");
  let softwareAuthentication = '';
  if (sauthentication !== null && sauthentication !== undefined && sauthentication != "") {
    if (sauthentication == "1") {
      softwareAuthentication = "Yes"
    } else if (sauthentication == "2") {
      softwareAuthentication = "No"
    } else if (sauthentication == "3") {
      softwareAuthentication = "N/A"
    }
    let customfield_68100 = { value: softwareAuthentication }
    fields.customfield_68100 = customfield_68100;
  }

  let shosting = localStorage.getItem("shosting");
  let softwareHosting = '';
  if (shosting !== null && shosting !== undefined && shosting != "") {
    if (shosting == "1") {
      softwareHosting = "102605"
    } else if (shosting == "2") {
      softwareHosting = "102606"
    } else if (shosting == "3") {
      softwareHosting = "102607"
    } else if (shosting == "4") {
      softwareHosting = "102608"
    }
    let customfield_67302 = { id: softwareHosting }
    fields.customfield_67302 = customfield_67302;
  }

  let dtransmission = localStorage.getItem("dtransmission");
  let dataTranmission: any;
  let customfield_57605Array: any[] = [];
  let dtransmissionArray: any[] = [];
  if (dtransmission !== null && dtransmission !== undefined && dtransmission != "") {
    dtransmissionArray = dtransmission.split(',');
    if (dtransmissionArray != null && dtransmissionArray.length > 0) {
      if (dtransmissionArray.filter(x => x == '1').length > 0) {
        dataTranmission = { id: "86046" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '2').length > 0) {
        dataTranmission = { id: "86047" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '3').length > 0) {
        dataTranmission = { id: "86048" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '4').length > 0) {
        dataTranmission = { id: "86049" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '5').length > 0) {
        dataTranmission = { id: "103500" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '6').length > 0) {
        dataTranmission = { id: "86050" }
        customfield_57605Array.push(dataTranmission);
      }
      if (dtransmissionArray.filter(x => x == '7').length > 0) {
        dataTranmission = { id: "86051" }
        customfield_57605Array.push(dataTranmission);
      }
      let customfield_57605 = customfield_57605Array
      fields.customfield_57605 = customfield_57605;
    }
  }


  let sintegration = localStorage.getItem("sintegration");
  let systemIntegration: any;
  let customfield_57606Array: any[] = [];
  let sintegrationArray: any[] = [];
  if (sintegration !== null && sintegration !== undefined && sintegration != "") {
    sintegrationArray = sintegration.split(',');
    if (sintegrationArray != null && sintegrationArray.length > 0) {
      if (sintegrationArray.filter(x => x == '1').length > 0) {
        systemIntegration = { id: "86052" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '2').length > 0) {
        systemIntegration = { id: "86053" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '3').length > 0) {
        systemIntegration = { id: "86054" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '4').length > 0) {
        systemIntegration = { id: "86055" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '5').length > 0) {
        systemIntegration = { id: "86056" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '6').length > 0) {
        systemIntegration = { id: "102611" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '7').length > 0) {
        systemIntegration = { id: "86057" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '8').length > 0) {
        systemIntegration = "86058"
        systemIntegration = { id: "86058" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '9').length > 0) {
        systemIntegration = { id: "86059" }
        customfield_57606Array.push(systemIntegration);
      }
      if (sintegrationArray.filter(x => x == '10').length > 0) {
        systemIntegration = { id: "86060" }
        customfield_57606Array.push(systemIntegration);
      }
      let customfield_57606 = customfield_57606Array
      fields.customfield_57606 = customfield_57606;
    }
  }


  let ccustomers = localStorage.getItem("ccustomers");
  let contactCustomers = '';
  if (ccustomers !== null && ccustomers !== undefined && ccustomers != "") {
    if (ccustomers == "1") {
      contactCustomers = "Yes"
    } else if (ccustomers == "2") {
      contactCustomers = "No"
    }
    let customfield_67300 = { value: contactCustomers }
    fields.customfield_67300 = customfield_67300;
  }


  let subcontractors = localStorage.getItem("subcontractors");
  let contractors = '';
  if (subcontractors !== null && subcontractors !== undefined && subcontractors != "") {
    if (subcontractors == "1") {
      contractors = "Yes"
    } else if (subcontractors == "2") {
      contractors = "No"
    }
    let customfield_67301 = { value: contractors }
    fields.customfield_67301 = customfield_67301;
  }

  let dataset = localStorage.getItem("dataset");
  let dataElement = '';
  if (dataset !== null && dataset !== undefined && dataset != "") {
    if (dataset == "1") {
      dataElement = "Yes"
    } else if (dataset == "2") {
      dataElement = "No"
    }
    let customfield_67500 = { value: dataElement }
    fields.customfield_67500 = customfield_67500;
  }


  if (this.virascore !== null && this.virascore !== undefined && this.virascore != "") {
    let customfield_66500 = this.virascore
    fields.customfield_66500 = customfield_66500;
  }

  if (this.level !== null && this.level !== undefined && this.level != "") {
    let customfield_43202 = { value: this.level }
    fields.customfield_43202 = customfield_43202;
  }

  let describePS = localStorage.getItem("describePS");
  if (describePS !== null && describePS !== 'null' && describePS !== undefined && describePS != "") {
    fields.customfield_67800 = describePS;
  }

  let describePD = localStorage.getItem("describePD");
  if (describePD !== null && describePD !== 'null' && describePD !== undefined && describePD != "") {
    fields.customfield_67801 = describePD;
  }

  let describePT = localStorage.getItem("describePT");
  if (describePT !== null && describePT !== 'null' && describePT !== undefined && describePT != "") {
    fields.customfield_67802 = describePT;
  }

  let describeSI = localStorage.getItem("describeSI");
  if (describeSI !== null && describeSI !== 'null' && describeSI !== undefined && describeSI != "") {
    fields.customfield_67803 = describeSI;
  }

  let describeCE = localStorage.getItem("describeCE");
  if (describeCE !== null && describeCE !== 'null' && describeCE !== undefined && describeCE != "") {
    fields.customfield_67900 = describeCE;
  }

  let othersoftware = localStorage.getItem("othersoftware");
  if (othersoftware !== null && othersoftware !== 'null' && othersoftware !== undefined && othersoftware != "") {
    fields.customfield_68300 = othersoftware;
  }

  return fields;
}
}
