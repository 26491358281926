import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { VendorQuestionnaireComponent } from './Vendor_Questionnaire/vendor-questionnaire.component'
import {ResilliencyContinuityComponent} from './Resiliency_Continuity_Page/resilliency-continuity.component'
import { DataSecurityComponent } from './Data_Security_Page/data-security.component'
import { VendorDetailsComponent } from './Vendor_Details/vendor-details.component'
import { ReputationalPageComponent } from './Reputational_Page/reputational-page.component'
import { LoginComponent } from './authenticate/login.component';
import { OktaService } from './authenticate/okta.service';
import { AuthGuard } from './authenticate/auth.guard';
import { OAuthModule } from 'angular-oauth2-oidc';
import { InformationSecurityComponent } from './Information_Security_Page/information-security.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
 

@NgModule({
  declarations: [
    AppComponent, 
    VendorDetailsComponent,
    ReputationalPageComponent,
    LoginComponent,
    InformationSecurityComponent,
    VendorQuestionnaireComponent,
    ResilliencyContinuityComponent,
    DataSecurityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    NgxPaginationModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
 ],
  providers: [HttpClientModule,  OktaService,  AuthGuard],
  bootstrap: [AppComponent, LoginComponent, InformationSecurityComponent, VendorDetailsComponent, ReputationalPageComponent, VendorQuestionnaireComponent, ResilliencyContinuityComponent, DataSecurityComponent]
})
export class AppModule { }




