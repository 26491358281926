import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'data-security.component',
  templateUrl: './data-security.component.html',
  styleUrls: ['./data-security.component.css']
})
export class DataSecurityComponent implements OnInit {
  DataSecurityForm: FormGroup;
  finalTickets: any[] = [];
  ticketResult: any[] = [];
  ticketCount: number = 0;
  errorMessage: any;
  ticket: string = "";
  p: Number = 1;
  count: Number = 10;
  checkStatus = "Open";
  isOpen = true;
  user: any;
  password: any;
  adminView: any;
  dueTickets: string = "";
  auth: any;
  datatypes_dropdownList: any[] = [];
  intpropdtypes_dropdownList: any[] = [];
  pdatacollection_dropdownList: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  requireDescribe: boolean = false;
  requireDescribeOPD: boolean = false;
  requireDescribeIPD: boolean = false;

  constructor(private http: HttpClient, private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute) {

    this.DataSecurityForm = this.formBuilder.group({
      llmuse: ["", Validators.required],
      tusage: ["", Validators.required],
      dtypes: ["", Validators.required],
      intpropdtypes: [""],
      otherIntpropdaccess: [null],
      pdatacollection: ["", Validators.required],
      dlocation: ["", Validators.required],
      dsharing: ["", Validators.required],
      oadevelopment: ["", Validators.required],
      dvolume: ["", Validators.required],
      dataset: ["", Validators.required],
      describePD: [null]
    });
  }

  ngOnInit() {

    this.datatypes_dropdownList = [
      { item_id: "1", item_text: 'Personal Data about Employees (future, current, or previous)' },
      { item_id: "2", item_text: 'Personal Data of Clients or Prospective Client Data (terminal users, BW customers, data license subscribers)' },
      { item_id: "3", item_text: 'Personal Data of Others (tracking of web site visitors, event attendees, contingent staff, client prospects / sales leads)' },
      { item_id: "4", item_text: 'Client Trade Data, Security Searches or Monitors, Compliance & Audit Data (e.g. trades, trade system usage, orders, alerts, customized screens, audit findings)' },
      { item_id: "5", item_text: 'Other Client Data (e.g. inquiries, searches, tickets, subscriptions, account information, product orders, MSG or IB Data, adoption or usage)' },
      { item_id: "6", item_text: 'Intellectual Property (e.g. INDG proprietary data, software code, use of INDG brand name or logo, unpublished news stories or industry reports)' },
      { item_id: "7", item_text: 'Other INDG Confidential Information/Data (e.g. internal corporate documents or systems, ticketing functions, course material for L and D, research on INDG, product performance)' },
      { item_id: "8", item_text: 'Advertising or Marketing material /content created by vendor for INDG' },
      { item_id: "9", item_text: 'INDG marketing/advertising content only printed by vendor or digital marketing/advertising only distributed by vendor (vendor does not create the content)' },
      { item_id: "10", item_text: 'Physical Facilities data (permits, hardware installation orders, office blueprints, architecture designs)' },
      { item_id: "11", item_text: 'Data from Third Party Sources (e.g. pricing reports, quotes, exchange data, research reports)' },
      { item_id: "12", item_text: 'Publically Available Data (e.g. data content sets that exclude Personal Data such as email address, street address, name, etc...)' },
      { item_id: "13", item_text: 'Not applicable (e.g. vendor will not process, handle, store, collect, or have access to data)' },
      { item_id: "14", item_text: 'INDG brand or logo' },
    ];

    this.intpropdtypes_dropdownList = [
      { item_id: "1", item_text: 'Software code' },
      { item_id: "2", item_text: 'Unpublished INDG news stories' },
      { item_id: "3", item_text: 'Industry reports' },
      { item_id: "4", item_text: 'Proprietary data' },
      { item_id: "5", item_text: 'Corporate documents' },
      { item_id: "6", item_text: 'Ticketing functions' },
      { item_id: "7", item_text: 'Course material for L&D' },
      { item_id: "8", item_text: 'Proprietary Algorithms' },
      { item_id: "9", item_text: 'Licensing agreements with third-party providers, technology partners, etc.' },
      { item_id: "10", item_text: 'Other' },
    ];

    this.pdatacollection_dropdownList = [
      { item_id: "1", item_text: 'People’s First and Last Name' },
      { item_id: "2", item_text: 'People’s Business Contact Information (corporate email, business phone number, office address)' },
      { item_id: "3", item_text: 'People’s Personal Contact Information (personal email, personal phone number, home address)' },
      { item_id: "4", item_text: 'Client Trade Data, Security Searches or Monitors, Compliance & Audit Data (e.g. trades, trade system usage, orders, alerts, customized screens, audit findings)' },
      { item_id: "5", item_text: 'Passwords' },
      { item_id: "6", item_text: 'Social Media Handles and Content (Twitter or Instagram handles, Facebook posts, Snapchat Stories)' },
      { item_id: "7", item_text: 'Usage Data ( engagement data, HITS, TRAK, GUTS)' },
      { item_id: "8", item_text: 'Personnel personal trading data (brokerage account, 401k)' },
      { item_id: "9", item_text: 'People’s personal financial information (bank account, payroll, credit card)' },
      { item_id: "10", item_text: 'Biographical Information (date of birth, residence, marital status)     ' },
      { item_id: "11", item_text: 'Background and/or Criminal History (education, career history, criminal background records)' },
      { item_id: "12", item_text: 'Standard Demographic Data (age, income level, education level)' },
      { item_id: "13", item_text: 'Sensitive Demographic Data (ethnicity, religion, gender, sexual orientation, political affiliation, trade union membership)' },
      { item_id: "14", item_text: 'Personal Health Information (illness records, benefits information, disability information, health tracking records)' },
      { item_id: "15", item_text: 'Government Issued ID Information (passport numbers, driver’s license numbers, social security or national insurance numbers)' },
      { item_id: "16", item_text: 'Photos, Videos (personally identifiable photos or videos)' },
      { item_id: "17", item_text: 'CCTV footage (security or surveillance footage)' },
      { item_id: "18", item_text: 'Personal authentication data (B -Unit logins, Other authentication device logins)' },
      { item_id: "19", item_text: 'Device IP, Client IP Address, Cookie ID (IMEI numbers, Public or Private IP addresses)' },
      { item_id: "20", item_text: 'Geolocation data (geographical location of a person or device)' },
      { item_id: "21", item_text: 'Other (describe)' },
      { item_id: "22", item_text: 'Not Applicable' },
    ];

    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true,
    };

    let llmuse = localStorage.getItem("llmuse");
    let tusage = localStorage.getItem("tusage");
    let dtypes = localStorage.getItem("dtypes");
    let dtypesValue = localStorage.getItem("dtypesValue");
    let pdatacollection = localStorage.getItem("pdatacollection");
    let pdatacollectionValue = localStorage.getItem("pdatacollectionValue");
    let dlocation = localStorage.getItem("dlocation");
    let dsharing = localStorage.getItem("dsharing");
    let oadevelopment = localStorage.getItem("oadevelopment");
    let dvolume = localStorage.getItem("dvolume");
    let dataset = localStorage.getItem("dataset");
    let describePD = localStorage.getItem("describePD");

    if (llmuse != null && tusage !== null && dtypes !== null && pdatacollection !== null && dlocation !== null && dsharing !== null && oadevelopment !== null && dvolume !== null && dataset !== null &&
      dtypesValue !== null && pdatacollectionValue !== null) {

      let intpropdtypes = localStorage.getItem("intpropdtypes");
      let intpropdtypesValue = localStorage.getItem("intpropdtypesValue");
      let otherIntpropdaccess = localStorage.getItem("otherIntpropdaccess");

      let dtypesFinalArray: any[] = [];
      let intpropdtypesFinalArray: any[] = [];
      let pdatacollectionFinalArray: any[] = [];
      let dtypesArray: any[] = [];
      let dtypesValueArray: any[] = [];
      let intpropdtypesArray: any[] = [];
      let intpropdtypesValueArray: any[] = [];
      let pdatacollectionArray: any[] = [];
      let pdatacollectionValueArray: any[] = [];

      dtypesArray = dtypes.split(',')
      dtypesValueArray = dtypesValue.split(':')

      if (intpropdtypes !== null ) {
       intpropdtypesArray = intpropdtypes.split(',')
      }

      if (intpropdtypesValue !== null ) {
        intpropdtypesValueArray = intpropdtypesValue.split(':')
      }

      pdatacollectionArray = pdatacollection.split(',')
      pdatacollectionValueArray = pdatacollectionValue.split(':')

      for (let i = 0; i < dtypesArray.length; i++) {
        dtypesFinalArray.push({ item_id: dtypesArray[i], item_text: dtypesValueArray[i] });
      }

      for (let i = 0; i < intpropdtypesArray.length; i++) {
        intpropdtypesFinalArray.push({ item_id: intpropdtypesArray[i], item_text: intpropdtypesValueArray[i] });
      }

      for (let i = 0; i < pdatacollectionArray.length; i++) {
        pdatacollectionFinalArray.push({ item_id: pdatacollectionArray[i], item_text: pdatacollectionValueArray[i] });
      }

      console.log(llmuse);
      console.log(tusage);
      console.log(dtypesFinalArray);
      console.log(intpropdtypesFinalArray);
      console.log(pdatacollectionFinalArray);
      console.log(dlocation);
      console.log(dsharing);
      console.log(oadevelopment);
      console.log(dvolume);
      console.log(dataset);
      console.log(describePD);
      console.log(otherIntpropdaccess);

      if (describePD !== null && describePD !== 'null' && describePD !== undefined && describePD !== ''  &&
        intpropdtypes!== null && intpropdtypes !== 'null' && intpropdtypes !== undefined && intpropdtypes !== '' &&
        otherIntpropdaccess !== null && otherIntpropdaccess !== 'null' && otherIntpropdaccess !== undefined && otherIntpropdaccess !== '') {
        this.requireDescribe = true;
        this.requireDescribeIPD = true;
        this.requireDescribeOPD = true;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [intpropdtypesFinalArray, Validators.required],
          otherIntpropdaccess: [otherIntpropdaccess, Validators.required],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD: [describePD, Validators.required],
        });
      } else if (describePD !== null && describePD !== 'null' && describePD !== undefined && describePD !== ''  &&
        intpropdtypes!== null && intpropdtypes !== 'null' && intpropdtypes !== undefined && intpropdtypes !== '') {
        this.requireDescribe = true;
        this.requireDescribeIPD = true;
        this.requireDescribeOPD = false;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [''],
          otherIntpropdaccess: [null],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD: [describePD, Validators.required],
        });
      } else if (describePD !== null && describePD !== 'null' && describePD !== undefined && describePD !== '') {
        this.requireDescribe = true;
        this.requireDescribeIPD = false;
        this.requireDescribeOPD = false;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [''],
          otherIntpropdaccess: [null],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD: [describePD, Validators.required],
        });
      } else if (intpropdtypes!== null && intpropdtypes !== 'null' && intpropdtypes !== undefined && intpropdtypes !== '' &&
        otherIntpropdaccess !== null && otherIntpropdaccess !== 'null' && otherIntpropdaccess !== undefined && otherIntpropdaccess !== '') {
        this.requireDescribe = false;
        this.requireDescribeOPD = true;
        this.requireDescribeIPD = true;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [intpropdtypesFinalArray, Validators.required],
          otherIntpropdaccess: [otherIntpropdaccess, Validators.required],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD:[null],
        });
      } else if (intpropdtypes!== null && intpropdtypes !== 'null' && intpropdtypes !== undefined && intpropdtypes !== '') {
        this.requireDescribe = false;
        this.requireDescribeOPD = false;
        this.requireDescribeIPD = true;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [intpropdtypesFinalArray, Validators.required],
          otherIntpropdaccess: [null],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD:[null],
        });
      }
      else {
        this.requireDescribe = false;
        this.requireDescribeOPD = false;
        this.requireDescribeIPD = false;
        this.DataSecurityForm = this.formBuilder.group({
          llmuse: [llmuse, Validators.required],
          tusage: [tusage, Validators.required],
          dtypes: [dtypesFinalArray, Validators.required],
          intpropdtypes: [''],
          otherIntpropdaccess: [null],
          pdatacollection: [pdatacollectionFinalArray, Validators.required],
          dlocation: [dlocation, Validators.required],
          dsharing: [dsharing, Validators.required],
          oadevelopment: [oadevelopment, Validators.required],
          dvolume: [dvolume, Validators.required],
          dataset: [dataset, Validators.required],
          describePD: [null],
        });
      }
    }
  }

  // Data Types
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  // Personal Data Collection
  onItemSelectPersonalDataCollection(item: any) {
    console.log('onItemSelect', item);
    if (item.item_id === '21') {
      this.requireDescribe = true;
      this.DataSecurityForm.controls['describePD'].setValidators(Validators.required);
    }
  }
  onItemDeSelectPersonalDataCollection(item: any) {
    console.log('onItemDeSelect', item);
    if (item.item_id === '21') {
      this.requireDescribe = false;
      this.DataSecurityForm.controls['describePD'].clearValidators();
      this.DataSecurityForm.controls['describePD'].updateValueAndValidity();
      this.DataSecurityForm.controls['describePD'].reset();
      localStorage.setItem("describePD", '');
    }
  }
  onSelectAllPersonalDataCollection(items: any) {
    console.log('onSelectAll', items);
    this.requireDescribe = true;
    this.DataSecurityForm.controls['describePD'].setValidators(Validators.required);
  }
  onUnSelectAllPersonalDataCollection() {
    console.log('onUnSelectAll fires');
    this.requireDescribe = false;
    this.DataSecurityForm.controls['describePD'].clearValidators();
    this.DataSecurityForm.controls['describePD'].updateValueAndValidity();
    this.DataSecurityForm.controls['describePD'].reset();
    localStorage.setItem("describePD", '');
  }

    // Intellectural Property Data Type
    onItemSelectdatatypes(item: any) {
      console.log('onItemSelect', item);
      if (item.item_id === '6') {
        this.requireDescribeIPD = true;
        this.DataSecurityForm.controls['intpropdtypes'].setValidators(Validators.required);
      }
    }
    onItemDeSelectdatatypes(item: any) {
      console.log('onItemDeSelect', item);
      if (item.item_id === '6') {
        this.requireDescribeIPD = false;
        this.DataSecurityForm.controls['intpropdtypes'].clearValidators();
        this.DataSecurityForm.controls['intpropdtypes'].updateValueAndValidity();
        this.DataSecurityForm.controls['intpropdtypes'].reset();
        localStorage.setItem("intpropdtypes", '');
        this.requireDescribeOPD = false;
        this.DataSecurityForm.controls['otherIntpropdaccess'].clearValidators();
        this.DataSecurityForm.controls['otherIntpropdaccess'].updateValueAndValidity();
        this.DataSecurityForm.controls['otherIntpropdaccess'].reset();
        localStorage.setItem("otherIntpropdaccess", '');
      }
    }
    onSelectAlldatatypes(items: any) {
      console.log('onSelectAll', items);
      this.requireDescribeIPD = true;
      this.DataSecurityForm.controls['intpropdtypes'].setValidators(Validators.required);
    }
    onUnSelectAlldatatypes() {
      console.log('onUnSelectAll fires');
      this.requireDescribeIPD = false;
      this.DataSecurityForm.controls['intpropdtypes'].clearValidators();
      this.DataSecurityForm.controls['intpropdtypes'].updateValueAndValidity();
      this.DataSecurityForm.controls['intpropdtypes'].reset();
      localStorage.setItem("intpropdtypes", '');
      this.requireDescribeOPD = false;
      this.DataSecurityForm.controls['otherIntpropdaccess'].clearValidators();
      this.DataSecurityForm.controls['otherIntpropdaccess'].updateValueAndValidity();
      this.DataSecurityForm.controls['otherIntpropdaccess'].reset();
      localStorage.setItem("otherIntpropdaccess", '');
    }

   // Other Intellectual Property Data Access
   onItemSelectintpropdtypes(item: any) {
    console.log('onItemSelect', item);
    if (item.item_id === '10') {
      this.requireDescribeOPD = true;
      this.DataSecurityForm.controls['otherIntpropdaccess'].setValidators(Validators.required);
    }
  }
  onItemDeSelectintpropdtypes(item: any) {
    console.log('onItemDeSelect', item);
    if (item.item_id === '10') {
      this.requireDescribeOPD = false;
      this.DataSecurityForm.controls['otherIntpropdaccess'].clearValidators();
      this.DataSecurityForm.controls['otherIntpropdaccess'].updateValueAndValidity();
      this.DataSecurityForm.controls['otherIntpropdaccess'].reset();
      localStorage.setItem("otherIntpropdaccess", '');
    }
  }
  onSelectAllintpropdtypes(items: any) {
    console.log('onSelectAll', items);
    this.requireDescribeOPD = true;
    this.DataSecurityForm.controls['otherIntpropdaccess'].setValidators(Validators.required);
  }
  onUnSelectAllintpropdtypes() {
    console.log('onUnSelectAll fires');
    this.requireDescribeOPD = false;
    this.DataSecurityForm.controls['otherIntpropdaccess'].clearValidators();
    this.DataSecurityForm.controls['otherIntpropdaccess'].updateValueAndValidity();
    this.DataSecurityForm.controls['otherIntpropdaccess'].reset();
    localStorage.setItem("otherIntpropdaccess", '');
  }

  onSubmit(): void {
    console.warn('Form Values', this.DataSecurityForm.value);
    console.log(this.DataSecurityForm.valid);
    if (this.DataSecurityForm.valid) {

      let datatypesID = '';
      let datatypesValue = '';
      const dtypesArray: any[] = this.DataSecurityForm.value.dtypes;
      dtypesArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (datatypesID !== '') {
            datatypesID = datatypesID + ',' + item.item_id;
          } else {
            datatypesID = item.item_id;
          }
          if (datatypesValue !== '') {
            datatypesValue = datatypesValue + ':' + item.item_text;
          } else {
            datatypesValue = item.item_text;
          }
        }
      })

      let intpropdatatypesID = '';
      let intpropdatatypesValue = '';
      const intpropdtypesArray: any[] = this.DataSecurityForm.value.intpropdtypes;
      if (intpropdtypesArray  != null && intpropdtypesArray != undefined && intpropdtypesArray.length > 0) {
      intpropdtypesArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (intpropdatatypesID !== '') {
            intpropdatatypesID = intpropdatatypesID + ',' + item.item_id;
          } else {
            intpropdatatypesID = item.item_id;
          }
          if (intpropdatatypesValue !== '') {
            intpropdatatypesValue = intpropdatatypesValue + ':' + item.item_text;
          } else {
            intpropdatatypesValue = item.item_text;
          }
        }
      })
    }
      let pdatacollectionID = '';
      let pdatacollectionValue = '';
      const pdatacollectionArray: any[] = this.DataSecurityForm.value.pdatacollection;
      pdatacollectionArray.forEach(item => {
        if (item.item_id !== undefined && item.item_id !== null && item.item_id !== "") {
          if (pdatacollectionID !== '') {
            pdatacollectionID = pdatacollectionID + ',' + item.item_id;
          } else {
            pdatacollectionID = item.item_id;
          }
          if (pdatacollectionValue !== '') {
            pdatacollectionValue = pdatacollectionValue + ':' + item.item_text;
          } else {
            pdatacollectionValue = item.item_text;
          }
        }
      })

      localStorage.setItem("llmuse", this.DataSecurityForm.value.llmuse);
      localStorage.setItem("tusage", this.DataSecurityForm.value.tusage);
      localStorage.setItem("dtypes", datatypesID);
      localStorage.setItem("dtypesValue", datatypesValue);
      localStorage.setItem("pdatacollection", pdatacollectionID);
      localStorage.setItem("pdatacollectionValue", pdatacollectionValue);
      localStorage.setItem("dlocation", this.DataSecurityForm.value.dlocation);
      localStorage.setItem("dsharing", this.DataSecurityForm.value.dsharing);
      localStorage.setItem("oadevelopment", this.DataSecurityForm.value.oadevelopment);
      localStorage.setItem("dvolume", this.DataSecurityForm.value.dvolume);
      localStorage.setItem("dataset", this.DataSecurityForm.value.dataset);
      if (this.requireDescribe) {
        localStorage.setItem("describePD", this.DataSecurityForm.value.describePD);
      }
      if (this.requireDescribeIPD) {
        localStorage.setItem("intpropdtypes", intpropdatatypesID);
        localStorage.setItem("intpropdtypesValue", intpropdatatypesValue);
      }
      if (this.requireDescribeOPD) {
        localStorage.setItem("otherIntpropdaccess", this.DataSecurityForm.value.otherIntpropdaccess);
      }
      this.router.navigate(['../vendor-questionnaire-information-security'], { relativeTo: this.route });
    }
  }



}