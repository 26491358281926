import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {VendorDetailsComponent} from './Vendor_Details/vendor-details.component';
import {VendorQuestionnaireComponent} from './Vendor_Questionnaire/vendor-questionnaire.component';
import {ResilliencyContinuityComponent} from './Resiliency_Continuity_Page/resilliency-continuity.component';
import { DataSecurityComponent } from './Data_Security_Page/data-security.component'
import { AuthGuard } from './authenticate/auth.guard';
import { LoginComponent } from './authenticate/login.component';
import { InformationSecurityComponent } from './Information_Security_Page/information-security.component'
import { ReputationalPageComponent } from './Reputational_Page/reputational-page.component'


const routes: Routes = [
  { path: '', component: VendorDetailsComponent ,  canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'vendor-questionnaire-reputational', component: ReputationalPageComponent ,  canActivate: [AuthGuard] },
  { path: 'vendor-questionnaire-information-security', component: InformationSecurityComponent ,  canActivate: [AuthGuard]},
  { path: 'vendor-details', component: VendorDetailsComponent,  canActivate: [AuthGuard]},
  { path: 'vendor-details-submit', component: VendorDetailsComponent,  canActivate: [AuthGuard]},
  { path: 'vendor-questionnaire', component: VendorQuestionnaireComponent,  canActivate: [AuthGuard] },
  { path: 'vendor-questionnaire-resiliency-continuity', component: ResilliencyContinuityComponent,  canActivate: [AuthGuard]},
  { path: 'vendor-questionnaire-data-security', component: DataSecurityComponent,  canActivate: [AuthGuard]},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

