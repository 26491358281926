import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpClient , HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';

interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'resilliency-continuity.component',
  templateUrl: './resilliency-continuity.component.html',
  styleUrls: ['./resilliency-continuity.component.css']
})
export class ResilliencyContinuityComponent implements OnInit {
  ResiliencyForm: FormGroup;
  finalTickets: any[] = [];
  ticketResult: any[] = [];
  ticketCount: number = 0;
  errorMessage: any;
  ticket: string = "";
  p: Number = 1;
  count: Number = 10;
  checkStatus = "Open";
  isOpen = true;
  user : any ;
  password : any;
  adminView : any;
  dueTickets :string = "";
  auth: any;


  constructor(private http: HttpClient,private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    this.ResiliencyForm = this.formBuilder.group({
      pdelivery:["", Validators.required],
      bprocess:["", Validators.required],
      rpobjective:["", Validators.required],
      rtobjective:["", Validators.required],
    
    });
  }

  ngOnInit() {    
    let pdelivery = localStorage.getItem("pdelivery");
    let bprocess = localStorage.getItem("bprocess");
    let rpobjective = localStorage.getItem("rpobjective");
    let rtobjective = localStorage.getItem("rtobjective");


    if (pdelivery !== null && bprocess !== null && rpobjective !== null && rtobjective !== null) {
    
      console.log(pdelivery);
      console.log(bprocess);
      console.log(rpobjective);
      console.log(rtobjective);

      this.ResiliencyForm = this.formBuilder.group({
        pdelivery:[pdelivery, Validators.required],
        bprocess:[bprocess, Validators.required],
        rpobjective:[rpobjective, Validators.required],
        rtobjective:[rtobjective, Validators.required] 
      });
    }
  }

  
  onSubmit(): void {
    console.warn('Form Values', this.ResiliencyForm.value);
    console.log(this.ResiliencyForm.valid);
    if (this.ResiliencyForm.valid) {   
        localStorage.setItem("pdelivery", this.ResiliencyForm.value.pdelivery);
        localStorage.setItem("bprocess", this.ResiliencyForm.value.bprocess);
        localStorage.setItem("rpobjective", this.ResiliencyForm.value.rpobjective);
        localStorage.setItem("rtobjective", this.ResiliencyForm.value.rtobjective);     
        this.router.navigate(['../vendor-questionnaire-data-security'], { relativeTo: this.route });
    }
    
    }
  }
